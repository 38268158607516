import { TEvent, TVideo } from "@services/types";
import VideoList from "components/videos/VideoList";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { formatDate } from "utils/utils";

export default function VideosEvent() {
  const { event } = useOutletContext() as { event: TEvent };
  const videos = useLoaderData() as TVideo[];

  return (
    <div className="w-full flex flex-col justify-start space-y-20 p-10 bg-white">
      <section className="">
        <div className="w-full flex justify-between">
          <div className="space-y-3">
            <div className="flex items-center justify-between w-64">
              <h6>Total de vídeos:</h6>
              <p className="bg-red-100 w-28 font-bold text-center">
                {videos.length}
              </p>
            </div>
            <div className="flex items-center justify-between w-64">
              <h6>Data de entrega:</h6>
              <p className="bg-red-100 w-28 font-bold text-center">
                {formatDate(event.releaseDate)}
              </p>
            </div>
          </div>
          <div>
            {event.compressed_video_collection_url ? (
              <a
                href={event.compressed_video_collection_url}
                className="button text-white bg-red-400 hover:bg-red-500"
                download={`${event.title} - ${event.date}.zip`}
              >
                Baixar todos os vídeos
              </a>
            ) : (
              <p>Vídeos não disponíveis para download</p>
            )}
          </div>
        </div>
        <h2 className="mb-3">Vídeos gravados</h2>
        <VideoList videos={videos} />
      </section>
    </div>
  );
}
