import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";
import Footer from "./Footer";
import Logo from "../../assets/svg/Logo";
import { useTailwindScreenSize } from "utils/utils";

export default function UnauthenticatedLayout() {
  const { eventCode } = useParams();
  const screenSize = useTailwindScreenSize();
  const isMobile = screenSize === "sm";

  if (process.env.NODE_ENV === "production") {
    clarity.init("ngjj6db2ll");
  }

  return (
    <React.StrictMode>
      <div className="w-full h-screen flex flex-col bg-rose-50">
        <div className="grid grid-cols-1 md:grid-cols-2 grow">
          <div>
            <div className="w-full px-4">
              <Link to={`/public/${eventCode}/login`}>
                <Logo
                  width={isMobile ? 150 : 200}
                  height={isMobile ? 30 : 50}
                  color="#000000"
                />
              </Link>
            </div>
            <div className="mt-20">
              <Outlet />
            </div>
          </div>
          <div className="w-full h-full bg-[url('../../assets/images/bg-customer-login.png')] bg-cover bg-center hidden md:block"></div>
        </div>
        <Footer />
      </div>
    </React.StrictMode>
  );
}
