import { TLog } from "@services/types";
import React from "react";
import { useLoaderData } from "react-router-dom";
import { formatDateTime } from "utils/utils";

export default function LogsEvent() {
  const logs = useLoaderData() as TLog[];

  return (
    <div className="w-full flex flex-col justify-start p-10 bg-white">
      <div className="flex items-center text-red-400 font-century-gothic-bold border-b border-b-gray-300 py-3">
        <div className="w-52">DATA</div>
        <div>LOG</div>
      </div>
      {logs.length === 0 && (
        <div className="flex items-center justify-center text-black font-century-gothic py-3">
          <div>Sem logs para este evento</div>
        </div>
      )}

      {logs.map((log) => (
        <div
          key={log.id}
          className="flex items-center text-black font-century-gothic border-b border-b-gray-300 py-3"
        >
          <div className="w-52 min-w-[208px]">
            {formatDateTime(log.createdAt as string)}
          </div>
          <div>{log.message}</div>
        </div>
      ))}
    </div>
  );
}
