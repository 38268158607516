import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

export default function AppLayout() {
  return (
    <React.StrictMode>
      <div className="theme-layout">
        <Header />
        <div className="theme-body">
          <div className="theme-container">
            <Outlet />
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}
