import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import { InputText } from "components/ui/form";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "components/ui/Button";
import { useEffect, useMemo, useState } from "react";
import { TEvent, TLog } from "@services/types";
import { getEventByCode } from "../../services/events";
import * as logService from "../../services/logs";

type Inputs = {
  email: string;
  password: string;
};

export default function Login() {
  const { eventCode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [event, setEvent] = useState<TEvent | null>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { email: "", password: "" } });

  const eventUrl = useMemo(() => {
    if (!eventCode) return "";

    return `/customer-panel/${eventCode}`;
  }, [eventCode]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { email, password } = data;

    let log;

    try {
      await FirebaseAuthService.loginUser(email, password);

      log = {
        eventId: event?.id as string,
        message: `Login na área logada com o email ${email}`,
      };

      navigate(eventUrl, { replace: true });
    } catch (error) {
      setError("E-mail ou senha inválidos");

      log = {
        eventId: event?.id as string,
        message: `Tentativa de login na área logada com o email ${email}`,
      };

      console.warn("auth error", error);
    } finally {
      await logService.createLog(log as TLog);
    }
  };

  const handleLoginWithGoogle = async () => {
    let log;

    try {
      const userCredential = await FirebaseAuthService.loginWithGoogle();
      const user = userCredential.user;

      log = {
        eventId: event?.id as string,
        message: `Login na área logada com o email ${user?.email}`,
      };

      navigate(eventUrl, { replace: true });
    } catch (error) {
      log = {
        eventId: event?.id as string,
        message: `Tentativa de login na área logada utilizando o Google.`,
      };

      console.log("Login error: ", error);
    } finally {
      await logService.createLog(log as TLog);
    }
  };

  useEffect(() => {
    const message = params.get("message");
    if (message) {
      setMessage(message);
    }
  }, [params]);

  useEffect(() => {
    const error = params.get("error");
    if (error) {
      setError(error);
    }
  }, [params]);

  useEffect(() => {
    if (!eventCode) return;

    const fetchEventByCode = async (eventCode: string) => {
      const event = await getEventByCode(eventCode);
      setEvent(event as TEvent);
    };

    fetchEventByCode(eventCode);
  }, [eventCode]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex min-w-[300px] flex-col items-start">
        <h2 className="text-3xl">Login</h2>
        <form
          className="w-full flex gap-3 mt-10"
          autoComplete="off"
          noValidate={true}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            type="email"
            label="E-mail"
            register={register("email", { required: "Campo obrigatório" })}
            field="email"
            isEditable={true}
            error={errors.email}
          />

          <InputText
            type="password"
            label="Senha"
            register={register("password")}
            field="password"
            isEditable={true}
            error={errors.password}
          />

          <Link
            to={`/public/${eventCode}/forgot-password`}
            className="text-blue-400"
          >
            Esqueci minha senha
          </Link>

          <div className="w-full flex items-center justify-center mt-5">
            <Button type="submit" className="px-10">
              Entrar
            </Button>
          </div>
        </form>

        <div className="w-full flex items-center justify-center">
          <Button
            type="button"
            onClick={handleLoginWithGoogle}
            className="mt-2 flex items-center"
          >
            <span className="w-full">Faça login com o Google</span>
          </Button>
        </div>

        {message && <p className="text-green-600">{message}</p>}
        {error && <p className="text-red-400">{error}</p>}

        <div className="w-full flex items-center justify-center py-5">
          <span>-- OU --</span>
        </div>

        <div className="w-full flex items-center justify-center">
          <Link
            to={`/public/${eventCode}/new-account`}
            className="text-blue-400 mt-2"
          >
            Crie a sua conta
          </Link>
        </div>
      </div>
    </div>
  );
}
