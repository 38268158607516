import React from "react";
import EventList from "../../../components/events/EventList";
import { Form, useLoaderData } from "react-router-dom";
import { TEvent } from "@services/types";

type TEventExtended = TEvent & { filesCount: number; videosCount: number };

export default function Events() {
  const events = useLoaderData() as TEventExtended[];

  return (
    <>
      <div className="space-y-5 mb-5">
        <h1 className="">Eventos</h1>
        <header className="w-full flex items-center justify-between">
          <Form>
            <div className="flex items-center justify-start gap-2">
              <input
                type="text"
                name="search_term"
                placeholder="Pesquisar"
                className="w-[500px]"
              />
              <button
                type="submit"
                className="button bg-red-400 hover:bg-red-500 text-white max-w-[150px]"
              >
                Pesquisar
              </button>
            </div>
          </Form>
        </header>
      </div>
      <EventList events={events} />
    </>
  );
}
