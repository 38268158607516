import Button from "components/ui/Button";
import { InputText } from "components/ui/form";
import { useForm } from "react-hook-form";
import firebase from "libs/firebase/FirebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";

type Inputs = {
  email: string;
};

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { email: "" } });
  const { eventCode } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const loginPath = useMemo(() => {
    return `/public/${eventCode}/login`;
  }, [eventCode]);

  const loginUrl = useMemo(() => {
    return `${process.env.REACT_APP_PUBLIC_URL}${loginPath}`;
  }, [loginPath]);

  const onSubmit = async (data: Inputs) => {
    const { email } = data;

    const afterChangePasswordSettings = {
      url: `${loginUrl}?message=Senha alterada com sucesso!`,
      handleCodeInApp: true,
    };

    try {
      await sendPasswordResetEmail(
        firebase.auth,
        email,
        afterChangePasswordSettings
      );

      navigate(
        `${loginPath}?message=Email de recuperação enviado com sucesso!`,
        { replace: true }
      );
    } catch (error) {
      console.error("Error sending email", error);

      setError("Falha ao enviar e-mail de recuperação.");
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col min-w[300px]">
        <h2 className="text-3xl">Esqueci minha senha</h2>

        <form
          className="w-full flex gap-3 mt-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            register={register("email")}
            type="email"
            label="E-mail"
            field="email"
            error={errors.email}
            isEditable={true}
          />

          <div className="w-full flex items-center justify-center mt-5">
            <Button type="submit">Solicitar link de recuperação</Button>
          </div>
        </form>

        {error && <p className="text-red-400">{error}</p>}
      </div>
    </div>
  );
}
