import Button from "components/ui/Button";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";

type Props = {
  file: File | null;
  onFinish: (file: File) => void;
  onCancel: () => void;
};

type ImgCroppedArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export default function EventBannerResizer({
  file,
  onFinish,
  onCancel,
}: Props) {
  const [image, setImage] = useState<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<ImgCroppedArea>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(e.target.value));
  };

  const confirmCropHandler = useCallback(() => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = 600;
    canvasEle.height = 600;

    const context = canvasEle.getContext("2d");
    const imageCropped = new Image();
    imageCropped.src = image;
    imageCropped.onload = function () {
      context?.drawImage(
        imageCropped,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        canvasEle.width,
        canvasEle.height
      );

      canvasEle.toBlob(
        async (blob) => {
          if (!blob) {
            return;
          }
          const file = new File([blob], "banner.png", {
            type: "image/png",
            lastModified: Date.now(),
          });

          onFinish(file);
          setImage(null);
        },
        "image/png",
        1
      );
    };
  }, [croppedArea, image, onFinish]);

  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImage(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <div className="relative h-[500px] w-full max-w-[400px]">
      <div className="absolute w-full md:w-[400px] h-[400px]">
        <Cropper
          image={image}
          aspect={1}
          crop={crop}
          zoom={zoom}
          maxZoom={2}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="absolute bottom-0 w-full">
        <div className="flex flex-col items-start justify-center">
          <input
            type="range"
            className="p-0"
            min={1}
            max={2}
            step={0.01}
            value={zoom}
            onChange={onZoomChange}
          />

          <div className="mt-3 w-full flex items-center justify-around">
            <Button onClick={onCancel} className="max-w-[150px] bg-rose-200">
              Cancelar
            </Button>
            <Button
              onClick={confirmCropHandler}
              className="max-w-[150px] bg-green-200"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
