import { TEvent } from "@services/types";
import eventBannerDefault from "../assets/images/event-banner-default.jpeg";

export const eventTitleFormatter = (event: TEvent) => {
  const { titleOneText, titleTwoText } = event.customization?.template || {};

  // White template has two titles
  if (event.customization?.template?.name === "wedding-white") {
    return `${titleOneText} & ${titleTwoText}`;
  }

  return titleOneText;
};

export const eventBanner = (event: TEvent) => {
  if (!event.bannerUrl) {
    return eventBannerDefault;
  }

  return event.bannerUrl;
};
