import Button from "components/ui/Button";
import { InputText } from "components/ui/form";
import { useForm } from "react-hook-form";
import firebase from "libs/firebase/FirebaseConfig";
import { confirmPasswordReset } from "firebase/auth";
import { useState } from "react";

type Inputs = {
  newPassword: string;
};

export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { newPassword: "" } });
  const [error, setError] = useState<string | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");
  const continueUrl = urlParams.get("continueUrl");

  const onSubmit = async (data: Inputs) => {
    const { newPassword } = data;

    try {
      await confirmPasswordReset(firebase.auth, oobCode as string, newPassword);

      window.location.href = continueUrl as string;
    } catch (error) {
      console.error("Error sending email", error);

      setError("Código inválido ou expirado. Por favor, solicite um novo.");
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col min-w[300px]">
        <h2 className="text-3xl">Alterar senha</h2>

        <form
          className="w-full flex gap-3 mt-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            register={register("newPassword")}
            type="password"
            label="Nova senha"
            field="newPassword"
            error={errors.newPassword}
            isEditable={true}
          />

          <div className="w-full flex items-center justify-center mt-5">
            <Button type="submit">Atualizar</Button>
          </div>
        </form>

        {error && <p className="text-red-400">{error}</p>}
      </div>
    </div>
  );
}
