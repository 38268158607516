import FirebaseFirestore from "@firebase/firestore-types";
import { TQuery, TQueryDocument } from "@libs/firebase/Firebase.types";
import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import { ActionFunctionArgs } from "react-router-dom";
import { getEventById } from "./events";
import * as logService from "./logs";

const COLLECTION = "uploads";

export const getUploadsByEventId = async (eventId: string) => {
  let queries: TQuery[] = [];

  queries.push({
    field: "eventId",
    condition: "==" as FirebaseFirestore.WhereFilterOp,
    value: eventId,
  });

  const args: TQueryDocument = {
    collection: COLLECTION,
    orderByField: "updatedAt",
    orderByDirection: "asc",
    queries: queries,
  };

  const response = await FirebaseFirestoreService.readDocuments(args);

  const uploads = response.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return uploads;
};

export const uploadsLoader = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;

  if (!id) return null;

  return await getUploadsByEventId(id);
};

export const countUploadsByEvent = async (eventId: string) => {
  const queries = [
    {
      field: "eventId",
      value: eventId,
      condition: "==",
    },
  ] as TQuery[];
  return await FirebaseFirestoreService.countDocuments(COLLECTION, queries);
};

export const uploadDownloadLoader = async (args: ActionFunctionArgs) => {
  const { request } = args;
  const event = await getEventById(args);
  const uploadsCount = await countUploadsByEvent(event.id);

  const url = new URL(request.url);
  const email = url.searchParams.get("email");

  const log = {
    eventId: event.id as string,
    message: `Visitou a página de download. Email: ${
      email ? email : "Não identificado."
    }`,
  };

  await logService.createLog(log);

  return {
    event,
    uploadsCount,
  };
};
