import { createEvent } from "../../../services/events";
import EventForm, {
  TEventFormValues,
} from "../../../components/events/EventForm";
import { useNavigate } from "react-router-dom";

export default function CreateEvent() {
  const navigate = useNavigate();

  const handleCreate = async (data: TEventFormValues) => {
    const eventId = await createEvent(data);

    if (!eventId) {
      throw Error("event can't be saved");
    }

    navigate(`/admin/events/${eventId}`);
  };

  return <EventForm isEditable={true} onSubmit={handleCreate} />;
}
