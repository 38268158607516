import { useTailwindScreenSize } from "utils/utils";
import { Instagram, QuestionMark, Whatsapp } from "../../assets/svg";
import Logo from "../../assets/svg/Logo";
import { Link, useParams } from "react-router-dom";

const Footer = () => {
  const { eventCode } = useParams();
  const screenSize = useTailwindScreenSize();
  const isMobile = screenSize === "sm";
  const baseUrl = `/customer-panel/${eventCode}`;

  return (
    <footer className="justify-self-end place-self-end w-full h-[60px] px-4 bg-black">
      <div className="h-full flex items-center justify-between max-w-7xl mx-auto">
        <Link to="/">
          <Logo
            width={isMobile ? 150 : 200}
            height={isMobile ? 30 : 50}
            color="#FFFFFF"
          />
        </Link>

        <div className="flex flex-col items-center justify-center gap-1">
          <div className="flex gap-2">
            <a
              href="https://www.instagram.com/capsuladotempoeventos"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram width={26} height={26} color="#FFFFFF" />
            </a>

            <a href="https://wa.link/7xcot0" target="_blank" rel="noreferrer">
              <QuestionMark
                width={26}
                height={26}
                color="#000000"
                outline="#FFFFFF"
              />
            </a>

            <a href="https://wa.link/7xcot0" target="_blank" rel="noreferrer">
              <Whatsapp width={26} height={26} color="#FFFFFF" />
            </a>
          </div>

          <Link
            to={`${baseUrl}/service-terms`}
            className="text-white text-xs no-underline"
          >
            Termos de Uso
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
