import { clsx } from "yet-another-react-lightbox";

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

const Card = (props: CardProps) => {
  const { className, children } = props;
  const classes = clsx("bg-white rounded-xl p-2 shadow-md", className);

  return <div className={classes}>{children}</div>;
};

export default Card;
