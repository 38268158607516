import Logo from "../../assets/svg/Logo";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { TEvent, TUpload } from "../../services/types";
import { formatDate } from "utils/utils";
import { createLog } from "../../services/logs";
import { getUploadsByEventId } from "../../services/uploads";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import { SpinnerIcon } from "../../assets/svg";
import classNames from "classnames";
import { isEventReleased } from "../.../../../services/events";
import dayjs from "dayjs";

const DAYS_UNTIL_CLOSE_DOWNLOADS = 35;

const buttonLabels = {
  initial: "Baixar arquivos",
  downloading: "Baixando <current>/<total> arquivos",
  done: "Download finalizado",
  error: "Continuar download",
};

type DownloadButtonProps = {
  onClick: () => void;
  status: "initial" | "downloading" | "done" | "error";
  progress?: Array<number>;
};

const DownloadButton = ({
  onClick,
  status,
  progress = [],
}: DownloadButtonProps) => {
  const disabled = status === "downloading" || status === "done";
  let label = buttonLabels[status];
  let bgColor;
  let hover;
  let isDisabled = false;
  let icon;

  switch (status) {
    case "downloading":
      bgColor = "bg-gray-400";
      isDisabled = true;

      icon = (
        <SpinnerIcon width={24} height={24} color="#ffffff" outline="#4b5563" />
      );

      // update the label with the current and total files
      const [current = 0, total = 0] = progress;

      if (total === 0) {
        label = "Iniciando download";
      } else {
        label = label
          .replace("<current>", current.toString())
          .replace("<total>", total.toString());
      }

      break;
    case "done":
      bgColor = "bg-green-400";
      isDisabled = true;
      break;
    default:
      bgColor = "bg-red-400";
      hover = "hover:bg-red-500";
      break;
  }

  const handleClick = async () => {
    onClick();
  };

  const btnClass = classNames(
    "button text-white text-lg font-century-gothic-bold px-4 py-6 flex justify-center",
    bgColor,
    hover,
    {
      "cursor-pointer": !isDisabled,
      "cursor-not-allowed": isDisabled,
    }
  );

  return (
    <button className={btnClass} onClick={handleClick} disabled={disabled}>
      {icon ? <span className="mr-2">{icon}</span> : null}
      {label}
    </button>
  );
};

function UploadsDownload() {
  const { event, uploadsCount } = useLoaderData() as {
    event: TEvent;
    uploadsCount: number;
  };

  const isReleased = useMemo(() => isEventReleased(event), [event]);
  const releaseDate = dayjs(event.releaseDate);

  // const [uploads, setUploads] = useState<TUpload[]>();
  const [downloadStatus, setDownloadStatus] = useState<
    "initial" | "downloading" | "done" | "error"
  >("initial");
  const [downloadProgress, setDownloadProgress] = useState<[number, number]>([
    0, 0,
  ]);

  const expireDate = useMemo(() => {
    return releaseDate.add(DAYS_UNTIL_CLOSE_DOWNLOADS, "day");
  }, [releaseDate]);

  const lastDownloadedFileKey = useMemo(() => {
    return `lastDownloadedFile-${event.id}`;
  }, [event]);

  const downloadLog = useCallback(async () => {
    const log = {
      eventId: event.id as string,
      message: `Clicou para fazer download.`,
    };
    await createLog(log);
  }, [event]);

  const getLastDownloadedFile = useCallback(
    () => localStorage.getItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const removeLastDownloadedFile = useCallback(
    () => localStorage.removeItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const saveLastDownloadedFile = useCallback(
    (filename: string) => localStorage.setItem(lastDownloadedFileKey, filename),
    [lastDownloadedFileKey]
  );

  // Check is the wasn't errors on the last visit
  useEffect(() => {
    if (getLastDownloadedFile() && downloadStatus === "initial") {
      console.debug("Download error found");

      setDownloadStatus("error");
    }
  }, [getLastDownloadedFile, downloadStatus]);

  const handleDownload = async () => {
    setDownloadStatus("downloading");

    const uploads = (await getUploadsByEventId(
      event.id as string
    )) as TUpload[];

    try {
      const lastDownloadedFile = getLastDownloadedFile();
      const lastDownloadedFileIndex = uploads.findIndex(
        (upload) => upload.fileName === lastDownloadedFile
      );
      const startIndex =
        lastDownloadedFileIndex > 0 ? lastDownloadedFileIndex + 1 : 0;

      await downloadAllFiles(uploads, startIndex);
    } catch (error) {
      setDownloadStatus("error");
    }
  };

  const downloadFile = async (upload: TUpload) => {
    const a = document.createElement("a");
    a.style.display = "none";

    const { fileStoragePath, convertedFileStoragePath } = upload;

    let publicTmpUrl, filename;

    if (convertedFileStoragePath) {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(convertedFileStoragePath)
      );
      filename = convertedFileStoragePath.split("/").pop() as string;
    } else {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(fileStoragePath)
      );
      filename = upload.fileName;
    }

    const response = await fetch(publicTmpUrl);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    a.href = objectUrl;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(objectUrl);
  };

  const downloadAllFiles = async (
    uploads: TUpload[],
    startIndex: number = 0
  ) => {
    if (!uploads) return;

    for (let i = startIndex; i < uploads.length; i++) {
      const upload = uploads[i];

      console.debug(
        `Downloading file ${i + 1} of ${uploads.length}. Current file: ${
          upload.fileName
        }`
      );

      await downloadFile(uploads[i]);

      saveLastDownloadedFile(upload.fileName);

      setDownloadProgress([i + 1, uploads.length]);
    }

    // clear the download status
    setDownloadStatus("done");
    removeLastDownloadedFile();
  };

  const handleDownloadReset = async () => {
    removeLastDownloadedFile();
    await handleDownload();
  };

  if (!event) {
    return (
      <div className="grid h-screen max-h-full bg-rose-50 overflow-hidden auto-cols-auto grid-rows-[35px_auto]">
        <header className="w-full bg-red-400 text-white">
          <div className="h-full flex items-center justify-between max-w-6xl mx-auto px-4">
            <Logo width={150} />
          </div>
        </header>
        <div className="theme-body">
          <div className="pt-12 pb-5 w-full h-full flex flex-col justify-start items-start max-w-6xl mx-auto px-6">
            <h6 className="w-full text-2xl font-playfair-display font-normal text-center mb-12">
              Evento não encontrado.
            </h6>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid h-screen max-h-full bg-rose-50 overflow-hidden auto-cols-auto grid-rows-[35px_auto]">
      <header className="w-full bg-red-400 text-white">
        <div className="h-full flex items-center justify-between max-w-6xl mx-auto px-4">
          <Logo width={150} />
        </div>
      </header>
      <div className="theme-body">
        <div className="pt-12 pb-5 w-full h-full flex flex-col justify-start items-start max-w-6xl mx-auto px-6">
          <h6 className="w-full font-playfair-display font-normal text-3xl text-center mb-8 px-3">
            {event.title}
          </h6>
          <div className="flex flex-col items-center bg-white rounded-xl pt-12 px-3 md:px-20 pb-6 mx-auto max-w-2xl shadow-lg">
            {isReleased ? (
              <>
                <div className="font-century-gothic space-y-5 mb-5 px-4">
                  <p className="text-center text-lg leading-8">
                    Seu ábum está liberado!
                  </p>
                  <p className="text-center text-lg leading-8">
                    Você recebeu{" "}
                    <span className="font-century-gothic-bold">
                      {uploadsCount} arquivos!
                    </span>
                  </p>
                  <p className="text-center text-lg leading-8">
                    Seus arquivos estão disponíveis para download até dia:{" "}
                    <span className="font-century-gothic-bold">
                      {formatDate(expireDate)}
                    </span>
                  </p>

                  <p className="text-center text-lg leading-8">
                    Para uma melhor experiência, recomendamos baixar os arquivos
                    no computador ou em aparelhos com sistema operacional
                    android.
                  </p>
                </div>

                <div onClick={downloadLog}>
                  <DownloadButton
                    onClick={handleDownload}
                    status={downloadStatus}
                    progress={downloadProgress}
                  />
                </div>

                {["done", "error"].includes(downloadStatus) && (
                  <p className="text-sm mt-5">
                    Caso você deseje reiniciar o processo de download,{" "}
                    <span
                      className="underline hover:no-underline focus:outline-none cursor-pointer"
                      onClick={handleDownloadReset}
                    >
                      clique aqui
                    </span>
                    .
                  </p>
                )}
              </>
            ) : (
              <div className="font-century-gothic space-y-5 mb-3 px-4">
                <h6 className="text-center text-lg font-century-gothic-bold">
                  Quase lá...
                </h6>
                <p className="text-center font-century-gothic text-lg">
                  Seus arquivos serão liberados no dia:
                </p>
                <div className="bg-red-100 w-40 mx-auto">
                  <p className="font-century-gothic-bold text-center text-lg p-2">
                    <strong>{formatDate(releaseDate)}</strong>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UploadsDownload);
