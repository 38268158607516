type Props = {
  width?: number;
  height?: number;
  outline?: string;
  color?: string;
};

export default function Logo({ width, height, outline, color }: Props) {
  return (
    <svg
      width={width || 2307}
      height={height || 332}
      stroke={outline || "transparent"}
      viewBox="0 0 2307 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.0002 250.808C59.5456 250.808 46.2122 247.625 35.0001 241.259C23.7879 234.893 15.1516 226.254 9.09093 215.341C3.03031 204.276 0 191.696 0 177.6C0 166.839 2.04546 156.608 6.13638 146.908C10.3788 137.056 16.0606 128.341 23.1819 120.762C30.4546 113.184 38.788 107.197 48.1819 102.801C57.7274 98.4058 67.8032 96.2081 78.4093 96.2081C85.9851 96.2081 92.4245 96.7385 97.7275 97.7995C103.031 98.7089 107.728 99.9973 111.818 101.665C116.061 103.18 120.303 104.999 124.546 107.121L125.682 146.908H121.591L107.728 119.171C101.97 115.078 96.8942 112.123 92.5002 110.304C88.2578 108.485 83.4093 107.576 77.9547 107.576C67.0456 107.576 57.2729 110.304 48.6365 115.76C40.1516 121.217 33.4092 128.719 28.4092 138.268C23.5607 147.666 21.1364 158.503 21.1364 170.78C21.1364 185.179 23.4849 197.532 28.1819 207.838C33.0304 217.993 39.4698 225.799 47.5001 231.256C55.6819 236.561 64.6971 239.213 74.5456 239.213C82.879 239.213 89.8487 238.076 95.4548 235.803C101.212 233.378 106.288 230.725 110.682 227.845L124.318 203.519H128.182L125.682 239.668C121.288 241.941 116.743 243.912 112.046 245.579C107.5 247.246 102.273 248.534 96.3639 249.444C90.4548 250.353 83.3335 250.808 75.0002 250.808Z"
        fill={color || "white"}
      />
      <path
        d="M145.24 248.534V244.897L156.831 238.986L207.286 120.08H216.831L267.058 238.758L280.24 244.897V248.534H230.695V244.897L246.604 238.531L232.286 203.973H185.013L170.695 238.986L187.513 244.897V248.534H145.24ZM189.786 192.606H227.513L208.422 146.68L189.786 192.606ZM212.058 104.165L231.831 63.924H250.468C250.468 66.1975 249.937 68.471 248.877 70.7445C247.968 73.0181 245.998 75.5189 242.968 78.2472L214.558 104.165H212.058Z"
        fill={color || "white"}
      />
      <path
        d="M295.73 248.534V244.897L312.094 237.394V132.357L295.73 124.854V121.217H346.185C380.882 121.217 398.231 133.267 398.231 157.366C398.231 165.096 395.882 171.765 391.185 177.373C386.488 182.981 379.973 187.301 371.64 190.332C363.458 193.212 353.988 194.652 343.231 194.652H331.412V237.394L350.049 244.897V248.534H295.73ZM331.412 132.584V183.284H343.912C354.367 183.284 362.625 181.086 368.685 176.691C374.897 172.144 378.003 165.778 378.003 157.593C378.003 148.651 374.822 142.285 368.458 138.496C362.246 134.555 353.609 132.584 342.549 132.584H331.412Z"
        fill={color || "white"}
      />
      <path
        d="M457.631 250.808C449.6 250.808 442.631 249.974 436.722 248.307C430.964 246.64 425.055 244.366 418.994 241.486L416.949 210.794H420.585L431.267 231.256C435.661 234.59 440.131 236.939 444.676 238.304C449.373 239.516 454.146 240.122 458.994 240.122C467.631 240.122 474.222 238.379 478.767 234.893C483.464 231.256 485.813 225.799 485.813 218.524C485.813 213.067 484.373 208.899 481.494 206.019C478.616 202.988 474.373 200.26 468.767 197.835C463.312 195.258 456.57 192.075 448.54 188.286C438.994 183.739 432.1 179.116 427.858 174.417C423.767 169.719 421.721 163.201 421.721 154.865C421.721 148.499 423.615 142.588 427.403 137.132C431.191 131.675 436.115 127.28 442.176 123.945C448.388 120.459 455.206 118.716 462.631 118.716C468.843 118.716 474.6 119.55 479.903 121.217C485.206 122.733 490.434 124.703 495.585 127.128L497.176 156.684H493.54L484.903 138.041C481.267 135.01 477.782 132.812 474.449 131.448C471.115 130.084 467.252 129.402 462.858 129.402C455.282 129.402 449.297 131.296 444.903 135.085C440.661 138.875 438.54 143.876 438.54 150.091C438.54 154.183 439.525 157.593 441.494 160.322C443.464 163.05 446.494 165.626 450.585 168.052C454.828 170.325 460.282 173.053 466.949 176.236C475.585 180.329 482.479 184.118 487.631 187.604C492.782 190.938 496.494 194.728 498.767 198.972C501.191 203.215 502.403 208.596 502.403 215.114C502.403 222.086 500.434 228.3 496.494 233.756C492.706 239.061 487.403 243.23 480.585 246.261C473.919 249.292 466.267 250.808 457.631 250.808Z"
        fill={color || "white"}
      />
      <path
        d="M592.452 250.808C582.3 250.808 573.209 249.141 565.179 245.806C557.149 242.32 550.785 236.864 546.088 229.437C541.542 221.858 539.27 212.006 539.27 199.881V132.357L522.906 124.854V121.217H574.952V124.854L558.588 132.357V198.289C558.588 213.295 561.921 223.829 568.588 229.891C575.255 235.803 584.27 238.758 595.633 238.758C602.3 238.758 608.361 237.47 613.815 234.893C619.27 232.317 623.588 228.452 626.77 223.298C630.103 217.993 631.77 211.249 631.77 203.064V131.902L612.224 124.854V121.217H660.861V124.854L644.724 132.13V199.881C644.724 212.006 642.452 221.858 637.906 229.437C633.361 236.864 627.149 242.32 619.27 245.806C611.543 249.141 602.603 250.808 592.452 250.808Z"
        fill={color || "white"}
      />
      <path
        d="M683.657 248.534V244.897L699.794 237.394V132.357L683.657 124.854V121.217H737.521V124.854L719.112 132.357V237.167H768.43L781.839 204.883H785.476V248.534H683.657Z"
        fill={color || "white"}
      />
      <path
        d="M792.969 248.534V244.897L804.56 238.986L855.014 120.08H864.56L914.787 238.758L927.969 244.897V248.534H878.423V244.897L894.333 238.531L880.014 203.973H832.742L818.423 238.986L835.242 244.897V248.534H792.969ZM837.514 192.606H875.242L856.151 146.68L837.514 192.606Z"
        fill={color || "white"}
      />
      <path
        d="M993.459 248.534V244.897L1009.82 237.394V132.357L993.459 124.854V121.217H1048.23C1063.38 121.217 1076.19 123.642 1086.64 128.492C1097.25 133.342 1105.28 140.163 1110.73 148.954C1116.34 157.593 1119.14 167.673 1119.14 179.192C1119.14 193.288 1116.26 205.565 1110.51 216.023C1104.75 226.33 1096.41 234.363 1085.51 240.122C1074.75 245.73 1061.87 248.534 1046.87 248.534H993.459ZM1045.28 132.584H1029.14V237.167H1046.64C1063.61 237.167 1076.57 232.317 1085.51 222.616C1094.6 212.916 1099.14 200.108 1099.14 184.194C1099.14 167.369 1094.52 154.562 1085.28 145.771C1076.04 136.98 1062.7 132.584 1045.28 132.584Z"
        fill={color || "white"}
      />
      <path
        d="M1211.24 250.808C1198.82 250.808 1187.68 248.004 1177.83 242.396C1168.14 236.636 1160.48 228.831 1154.88 218.979C1149.27 208.975 1146.47 197.607 1146.47 184.876C1146.47 172.144 1149.27 160.776 1154.88 150.773C1160.48 140.769 1168.14 132.963 1177.83 127.355C1187.68 121.596 1198.82 118.716 1211.24 118.716C1223.82 118.716 1234.95 121.596 1244.65 127.355C1254.5 132.963 1262.23 140.769 1267.83 150.773C1273.59 160.776 1276.47 172.144 1276.47 184.876C1276.47 197.607 1273.59 208.975 1267.83 218.979C1262.23 228.831 1254.5 236.636 1244.65 242.396C1234.95 248.004 1223.82 250.808 1211.24 250.808ZM1215.11 239.44C1228.14 239.44 1238.36 234.969 1245.79 226.026C1253.21 217.084 1256.92 205.262 1256.92 190.559C1256.92 178.586 1254.88 168.052 1250.79 158.957C1246.7 149.863 1240.94 142.815 1233.51 137.814C1226.24 132.66 1217.83 130.084 1208.29 130.084C1195.26 130.084 1184.95 134.479 1177.38 143.27C1169.8 152.061 1166.01 163.808 1166.01 178.51C1166.01 190.635 1168.06 201.321 1172.15 210.566C1176.39 219.661 1182.15 226.784 1189.42 231.938C1196.85 236.939 1205.41 239.44 1215.11 239.44Z"
        fill={color || "white"}
      />
      <path
        d="M1379.18 248.534V244.897L1401.9 235.803V110.986H1364.86L1349.4 144.407H1345.77V98.4816H1478.5V144.407H1474.86L1459.4 110.986H1422.36V235.803L1445.09 244.897V248.534H1379.18Z"
        fill={color || "white"}
      />
      <path
        d="M1496.64 248.534V244.897L1512.78 237.394V132.357L1496.64 124.854V121.217H1593.68V153.501H1590.05L1579.14 132.584H1532.09V176.918H1566.41L1573.23 161.458H1576.87V203.519H1573.23L1566.41 188.286H1532.09V237.167H1584.37L1595.73 212.613H1599.37V248.534H1496.64Z"
        fill={color || "white"}
      />
      <path
        d="M1622.07 248.534V244.897L1638.21 237.394L1642.53 132.357L1625.03 124.854V121.217H1665.71L1708.89 217.614L1753.89 121.217H1792.53V124.854L1775.71 131.22L1778.89 237.394L1793.44 244.897V248.534H1744.12V244.897L1759.12 237.621L1757.07 142.588L1707.3 249.444H1701.39L1653.89 142.588L1651.62 237.621L1668.21 244.897V248.534H1622.07Z"
        fill={color || "white"}
      />
      <path
        d="M1815.03 248.534V244.897L1831.4 237.394V132.357L1815.03 124.854V121.217H1865.49C1900.19 121.217 1917.53 133.267 1917.53 157.366C1917.53 165.096 1915.19 171.765 1910.49 177.373C1905.79 182.981 1899.28 187.301 1890.94 190.332C1882.76 193.212 1873.29 194.652 1862.53 194.652H1850.72V237.394L1869.35 244.897V248.534H1815.03ZM1850.72 132.584V183.284H1863.22C1873.67 183.284 1881.93 181.086 1887.99 176.691C1894.2 172.144 1897.31 165.778 1897.31 157.593C1897.31 148.651 1894.13 142.285 1887.76 138.496C1881.55 134.555 1872.91 132.584 1861.85 132.584H1850.72Z"
        fill={color || "white"}
      />
      <path
        d="M2003.3 250.808C1990.87 250.808 1979.74 248.004 1969.89 242.396C1960.19 236.636 1952.54 228.831 1946.93 218.979C1941.33 208.975 1938.53 197.607 1938.53 184.876C1938.53 172.144 1941.33 160.776 1946.93 150.773C1952.54 140.769 1960.19 132.963 1969.89 127.355C1979.74 121.596 1990.87 118.716 2003.3 118.716C2015.87 118.716 2027.01 121.596 2036.71 127.355C2046.56 132.963 2054.28 140.769 2059.89 150.773C2065.65 160.776 2068.53 172.144 2068.53 184.876C2068.53 197.607 2065.65 208.975 2059.89 218.979C2054.28 228.831 2046.56 236.636 2036.71 242.396C2027.01 248.004 2015.87 250.808 2003.3 250.808ZM2007.16 239.44C2020.19 239.44 2030.42 234.969 2037.84 226.026C2045.27 217.084 2048.98 205.262 2048.98 190.559C2048.98 178.586 2046.93 168.052 2042.84 158.957C2038.75 149.863 2033 142.815 2025.57 137.814C2018.3 132.66 2009.89 130.084 2000.34 130.084C1987.31 130.084 1977.01 134.479 1969.43 143.27C1961.86 152.061 1958.07 163.808 1958.07 178.51C1958.07 190.635 1960.12 201.321 1964.21 210.566C1968.45 219.661 1974.21 226.784 1981.48 231.938C1988.9 236.939 1997.46 239.44 2007.16 239.44Z"
        fill={color || "white"}
      />
      <path
        d="M2167.58 196.442C2159.25 208.602 2151.19 221 2143.45 233.57C2132.95 250.228 2123.36 268.442 2122.18 288.545C2121.62 298.646 2123.84 309.289 2130.33 317.349C2136.8 325.353 2146.5 330.618 2156.66 331.797C2182.7 334.236 2205.03 314.291 2217.45 293.063L2213.97 293.677C2231.71 308.728 2256.74 319.347 2279.94 311.824C2298.12 305.816 2310.3 287.719 2306.21 268.375C2300.02 239.739 2275.99 217.681 2253.75 200.966C2235.16 187.209 2216.1 174.335 2196.39 162.318C2172.12 147.278 2148.37 131.058 2125.17 114.398C2111.77 104.528 2098.21 92.856 2090.73 77.8266C2081.44 59.3978 2084.08 41.7258 2101.4 29.9229C2123.38 16.7282 2149.96 27.0748 2168.1 42.1686L2171.98 45.1426C2176.85 36.2803 2182.9 27.9537 2190.29 21.161C2207.21 5.08588 2230.92 -3.78334 2249.39 14.1673C2266.52 31.2691 2263.39 57.432 2253.5 77.5273C2250.12 84.8126 2244.2 96.1458 2239.84 103.032L2224.52 126.886C2229.68 119.345 2243.54 99.1779 2248.57 91.832C2264.53 69.4071 2274.19 34.0897 2253.06 11.7476C2233.44 -7.88671 2205.76 -0.546957 2187.19 16.4496C2179.96 22.9262 2173.98 30.6136 2169.07 38.9672L2172.44 38.3733C2152.82 21.6543 2122.33 9.48156 2098.23 23.8516C2085.27 32.0256 2076.93 47.6742 2078.86 63.1685C2080.94 81.3825 2092.77 96.5036 2105.71 108.57C2114.27 116.492 2122.41 123.288 2131.85 130.026C2150.8 143.207 2169.7 156.165 2189.38 168.358C2208.83 180.248 2227.94 193.056 2246.31 206.566C2259.93 216.683 2273.27 227.478 2283.97 240.684C2293.89 253.054 2303.25 269.354 2298.47 285.518C2295.36 295.614 2287.89 302.019 2277.9 305.382C2267.59 308.915 2256.2 308.866 2245.85 305.661C2234.36 302.143 2223.82 295.655 2214.64 287.785C2209.61 297.019 2203.13 304.849 2195.31 311.775C2183.18 322.718 2166.16 330.465 2149.91 325.443C2143.27 323.298 2138.71 320.672 2134.28 315.223C2124.88 303.903 2124.95 287.826 2128.29 274.146C2131.68 259.827 2137.81 246.798 2145.32 234.211C2152.82 221.513 2159.66 208.987 2167.66 196.599L2167.58 196.442Z"
        fill={color || "white"}
      />
      <path
        d="M2152.42 95.9274C2158.67 89.7369 2171.58 91.2903 2181.53 99.6532C2188.03 88.3889 2199 82.509 2207.62 86.1913C2217.23 90.3278 2221.74 101.422 2211.99 119.091C2205.61 130.678 2190.15 148.555 2190.15 148.555C2190.15 148.555 2166.94 134.699 2160.54 129.368C2144.61 116.171 2145.26 103.079 2152.42 95.9274Z"
        fill={color || "white"}
      />
      <path
        d="M2189.12 205.675C2192.46 207.146 2196.77 204.841 2199.25 200.46C2203.12 203.721 2207.91 204.42 2210.54 201.898C2213.52 199.086 2213.24 193.934 2207.75 189.298C2205.02 187.036 2200.84 184.343 2195.89 181.443C2195.89 181.443 2189.38 189.681 2187.64 192.389C2183.62 198.643 2184.55 203.624 2189.12 205.675Z"
        fill={color || "white"}
      />
      <path
        d="M2176.33 302.26C2188.49 308.003 2204.51 299.117 2213.79 282.961C2228.03 294.969 2245.84 297.544 2255.59 288.279C2266.52 277.947 2260.72 257.017 2245.33 241.723C2233.33 229.778 2213.13 216.933 2201.4 212.658C2201.4 212.658 2176.63 242.637 2170.85 253.09C2157.47 277.288 2160.56 294.808 2176.33 302.26Z"
        fill={color || "white"}
      />
    </svg>
  );
}
