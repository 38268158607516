import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
// import {
//   connectFirestoreEmulator,
//   getFirestore as getFirestoreLite,
// } from "firebase/firestore/lite";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
// const firestoreLite = getFirestoreLite(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);
const firestore = getFirestore(firebaseApp);

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  // connectFirestoreEmulator(firestoreLite, "localhost", 8085);
  connectFirestoreEmulator(firestore, "localhost", 8085);
  connectStorageEmulator(storage, "localhost", 9199);
}

const firebaseConfig = {
  auth,
  analytics,
  // firestoreLite,
  firestore,
  storage,
};

export default firebaseConfig;
