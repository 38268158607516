type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function Badge({ children, className = "" }: Props) {
  return (
    <span className={`p-1 rounded text-xs text-white ${className}`}>
      {children}
    </span>
  );
}
