import { TDocument, TQuery, TQueryDocument } from "./Firebase.types";
import firebase from "./FirebaseConfig";
// import {
//   addDoc,
//   doc,
//   getDoc,
//   collection as firestoreLiteCollection,
//   query,
//   where,
//   orderBy,
//   limit,
//   startAfter,
//   getDocs,
//   updateDoc,
//   deleteDoc,
//   writeBatch,
// } from "firebase/firestore/lite";

import {
  getCountFromServer,
  collection as firestoreCollection,
  addDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

//db lit
// const firestoreLite = firebase.firestoreLite;

//db
const firestore = firebase.firestore;

const createDocument = (collection: string, document: TDocument) => {
  return addDoc(firestoreCollection(firestore, collection), document);
};

const readDocument = (collection: string, id: string) => {
  return getDoc(doc(firestore, collection, id));
};

const readDocuments = async ({
  collection,
  queries,
  perPage,
  cursorId,
  orderByField,
  orderByDirection,
}: TQueryDocument) => {
  const collectionRef = firestoreCollection(firestore, collection);

  const queryConstraints = [];
  if (queries && queries.length > 0) {
    for (const query of queries) {
      queryConstraints.push(where(query.field, query.condition, query.value));
    }
  }

  if (orderByField && orderByDirection) {
    queryConstraints.push(orderBy(orderByField, orderByDirection));
  }

  if (perPage) {
    queryConstraints.push(limit(perPage));
  }

  if (cursorId) {
    const document = await readDocument(collection, cursorId);
    queryConstraints.push(startAfter(document));
  }

  const firestoreQuery = query(collectionRef, ...queryConstraints);

  return getDocs(firestoreQuery);
};

const updateDocument = (
  collection: string,
  id: string,
  document: TDocument
) => {
  return updateDoc(doc(firestore, collection, id), document);
};

const updateDocuments = async (collection: string, list: TDocument[]) => {
  try {
    const batch = writeBatch(firestore);

    list.forEach((item) => {
      const ref = doc(firestore, collection, item.id);
      batch.update(ref, { ...item });
    });

    await batch.commit();
  } catch (error) {
    throw error;
  }
};

const deleteDocument = (collection: string, id: string) => {
  return deleteDoc(doc(firestore, collection, id));
};

const countDocuments = async (collection: string, queries: TQuery[]) => {
  const queryConstraints = [];
  if (queries && queries.length > 0) {
    for (const query of queries) {
      queryConstraints.push(where(query.field, query.condition, query.value));
    }
  }
  const collectionRef = firestoreCollection(firestore, collection);
  const firestoreQuery = query(collectionRef, ...queryConstraints);
  const querySnapshot = await getCountFromServer(firestoreQuery);

  const totalCount = querySnapshot.data().count;
  return totalCount;
};

const FirebaseFirestoreService = {
  createDocument,
  readDocument,
  readDocuments,
  updateDocument,
  deleteDocument,
  updateDocuments,
  countDocuments,
};

export default FirebaseFirestoreService;

// const createDocument = (collection: string, document: TDocument) => {
//   return addDoc(firestoreLiteCollection(firestoreLite, collection), document);
// };

// const readDocument = (collection: string, id: string) => {
//   return getDoc(doc(firestoreLite, collection, id));
// };

// const readDocuments = async ({
//   collection,
//   queries,
//   perPage,
//   cursorId,
//   orderByField,
//   orderByDirection,
// }: TQueryDocument) => {
//   const collectionRef = firestoreLiteCollection(firestoreLite, collection);

//   const queryConstraints = [];
//   if (queries && queries.length > 0) {
//     for (const query of queries) {
//       queryConstraints.push(where(query.field, query.condition, query.value));
//     }
//   }

//   if (orderByField && orderByDirection) {
//     queryConstraints.push(orderBy(orderByField, orderByDirection));
//   }

//   if (perPage) {
//     queryConstraints.push(limit(perPage));
//   }

//   if (cursorId) {
//     const document = await readDocument(collection, cursorId);
//     queryConstraints.push(startAfter(document));
//   }

//   const firestoreQuery = query(collectionRef, ...queryConstraints);

//   return getDocs(firestoreQuery);
// };

// const updateDocument = (
//   collection: string,
//   id: string,
//   document: TDocument
// ) => {
//   return updateDoc(doc(firestoreLite, collection, id), document);
// };

// const updateDocuments = async (collection: string, list: TDocument[]) => {
//   try {
//     const batch = writeBatch(firestoreLite);

//     list.forEach((item) => {
//       const ref = doc(firestoreLite, collection, item.id);
//       batch.update(ref, { ...item });
//     });

//     await batch.commit();
//   } catch (error) {
//     throw error;
//   }
// };

// const deleteDocument = (collection: string, id: string) => {
//   return deleteDoc(doc(firestoreLite, collection, id));
// };
