import clsx from "clsx";

export type InputProps = {
  label: string;
  register: any;
  error: any;
  field: string;
  isEditable?: boolean;
  type?: string;
  defaultValue?: string;
  hint?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputText = ({
  label,
  register,
  error,
  field,
  isEditable,
  type,
  hint,
  disabled,
  ...rest
}: InputProps) => {
  const inputClasses = clsx("border mt-1", {
    "bg-transparent": !disabled,
    "bg-gray-200 cursor-not-allowed": disabled,
    "bg-white": isEditable && !disabled,
  });

  return (
    <div className="w-full flex flex-col items-start">
      <label
        htmlFor={field}
        className="flex items-center gap-1.5 text-xs font-century-gothic-bold"
      >
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>
      <input
        type={type || "text"}
        id={field}
        {...register}
        aria-invalid={error ? "true" : "false"}
        {...rest}
        autoComplete="none"
        defaultValue={rest.defaultValue}
        className={inputClasses}
        disabled={disabled}
      />
      {hint && <span className="mt-1 text-xs text-gray-500">{hint}</span>}
    </div>
  );
};

export default InputText;
