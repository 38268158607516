import { TVideo } from "@services/types";
import { getVideoMetadataByURL } from "../../services/videos";
import React, { useCallback, useEffect, useState } from "react";

type Props = {
  video: TVideo;
  videoIdx: number;
};

export default function VideoListItem({ video, videoIdx }: Props) {
  const [videoSize, setVideoSize] = useState<number | null>(null);
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);

  const fetchVideoSize = useCallback(async () => {
    const metadata = await getVideoMetadataByURL(video.videoUrl);
    if (metadata) {
      const size = metadata.size / 1000 / 1000;
      setVideoSize(size);
    }
  }, [video.videoUrl]);

  useEffect(() => {
    fetchVideoSize();
  }, [fetchVideoSize]);

  const onDownloadVideo = useCallback(async () => {
    const response = await fetch(video.videoUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = video.videoFileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setIsDownloaded(true);
  }, [video.videoUrl, video.videoFileName, setIsDownloaded]);

  return (
    <div className="w-full relative no-underline appearance-none flex items-center border border-gray-300 p-1 gap-4 bg-white rounded-md">
      <div className="h-32 bg-gray-200">
        {video.thumbnailUrl ? (
          <img
            className="h-full w-full object-cover rounded-md"
            src={video.thumbnailUrl}
            alt={video.thumbnailFileName}
          />
        ) : (
          <p className="h-full w-full flex items-center justify-center">
            Sem banner
          </p>
        )}
      </div>
      {/* Column 1 */}
      <div className="flex flex-col items-start self-stretch justify-between">
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">ID do vídeo</h6>
          <p>{video.id}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Data de criação</h6>
          <p>{video.createdAt}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Data de atualização</h6>
          <p>{video.updatedAt}</p>
        </div>
      </div>
      {/* Column 2 */}
      <div className="flex flex-col items-start self-stretch justify-between">
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Status</h6>
          <p>{video.status}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Extensão do arquivo</h6>
          <p>{video.videoFileExtension}</p>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="text-xs font-semibold">Nome do arquivo</h6>
          <p>{video.videoFileName}</p>
        </div>
      </div>
      {/* Action Column */}
      <div className="flex flex-col items-start self-stretch ml-auto px-2 justify-between">
        <h6 className="w-full text-right text-lg text-gray-950 font-semibold">
          #{videoIdx}
        </h6>
        <div className="w-full flex justify-end">
          <p className="font-medium text-base">
            {videoSize ? `${videoSize.toFixed(2)} MB` : "Calculando..."}
          </p>
        </div>
        <button
          onClick={onDownloadVideo}
          className={`${
            isDownloaded
              ? "bg-green-500 hover:bg-green-600"
              : "bg-red-400 hover:bg-red-500"
          } button no-underline appearance-none text-white py-2 px-4 rounded-md text-center font-semibold`}
        >
          {isDownloaded ? "Baixado" : "Baixar vídeo"}
        </button>
      </div>
    </div>
  );
}
