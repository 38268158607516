import { Button } from "components/ui";
import { InputCheckbox } from "components/ui/form";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { FormEvent, useState } from "react";
import ServiceTermsText from "./ServiceTermsText";

type TServiceTermsFormProps = {
  onAcceptTerms: () => void;
};

const ServiceTermsForm = ({ onAcceptTerms }: TServiceTermsFormProps) => {
  const [acceptTerms, setAcceptTerms] = useState(false);

  const {
    register,
    formState: { errors },
  } = useForm();

  const handleAcceptTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(e.target.checked);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (acceptTerms) {
      const nowInMilliseconds = new Date().getTime();

      Cookies.set("acceptTerms", nowInMilliseconds.toString(), {
        expires: 365,
      });

      onAcceptTerms();
    }
  };

  return (
    <div className="h-[90vh] overflow-y-auto">
      <ServiceTermsText />

      <form
        className="h-auto flex flex-col justify-center items-center pt-10"
        onSubmit={handleSubmit}
      >
        <InputCheckbox
          name="acceptTerms"
          label="Aceito os termos de uso"
          register={register("acceptTerms")}
          field="acceptTerms"
          error={errors.acceptTerms?.message}
          onChange={handleAcceptTermsChange}
        />

        <div className="flex justify-center mt-6">
          <Button type="submit" disabled={!acceptTerms}>
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ServiceTermsForm;
