import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import Logo from "../../assets/svg/Logo";
import { HamburguerIcon } from "../../assets/svg";
import React, { useCallback, useMemo, useState } from "react";
import {
  NavLink,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTailwindScreenSize } from "utils/utils";
import { isEventFilesAvailable } from "../../services/events";
import { TEvent } from "@services/types";

function NavigationLink({
  to,
  children,
  onClick,
}: {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        [
          isActive ? " text-black " : "text-red-400",
          "rounded-t-xl p-2 text-lg whitespace-nowrap font-normal no-underline hover:underline",
        ].join(" ")
      }
      to={to}
      end
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
}

export default function Header() {
  const { event } = useLoaderData() as { event: TEvent };
  const { eventCode } = useParams();
  const logoutUrl = `/public/${eventCode}/login`;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const screenSize = useTailwindScreenSize();
  const isMobile = screenSize === "sm";
  const navigate = useNavigate();

  const isGalleryAvailable = useMemo(
    () => isEventFilesAvailable(event),
    [event]
  );

  const handleToggleMenu = useCallback(
    () => setIsMenuOpen((isMenuOpen) => !isMenuOpen),
    []
  );

  const onLogout = useCallback(() => {
    FirebaseAuthService.logoutUser();

    navigate(logoutUrl, { replace: true });
  }, [navigate, logoutUrl]);

  return (
    <div className="w-full h-[48px] md:bg-white">
      <div className="h-full flex items-center justify-between max-w-7xl mx-auto">
        <NavigationLink to="/">
          <Logo
            width={isMobile ? 150 : 200}
            height={isMobile ? 30 : 50}
            color="#000000"
          />
        </NavigationLink>

        <div className="mr-3 md:hidden">
          <button onClick={handleToggleMenu}>
            <HamburguerIcon width={24} height={24} />
          </button>
        </div>

        <div className="hidden md:flex md:items-center gap-2">
          <NavigationLink to={`/customer-panel/${eventCode}`}>
            Home
          </NavigationLink>
          <NavigationLink to={`/customer-panel/${eventCode}/edit`}>
            Evento
          </NavigationLink>
          <NavigationLink to={`/customer-panel/${eventCode}/qrcode`}>
            QR Code
          </NavigationLink>
          {isGalleryAvailable && (
            <NavigationLink to={`/customer-panel/${eventCode}/gallery`}>
              Álbum
            </NavigationLink>
          )}
          <NavigationLink to={logoutUrl} onClick={onLogout}>
            Sair
          </NavigationLink>
        </div>

        {isMenuOpen && (
          <div
            className="md:hidden fixed inset-0 bg-white bg-opacity-90 z-50"
            onClick={handleToggleMenu}
          >
            <div className="flex flex-col gap-2 p-4">
              <NavigationLink to={`/customer-panel/${eventCode}`}>
                Home
              </NavigationLink>
              <NavigationLink to={`/customer-panel/${eventCode}/edit`}>
                Evento
              </NavigationLink>
              <NavigationLink to={`/customer-panel/${eventCode}/qrcode`}>
                QR Code
              </NavigationLink>
              {isGalleryAvailable && (
                <NavigationLink to={`/customer-panel/${eventCode}/gallery`}>
                  Álbum
                </NavigationLink>
              )}
              <NavigationLink to={logoutUrl} onClick={onLogout}>
                Sair
              </NavigationLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
