import { TVideo } from "@services/types";
import React from "react";
import VideoListItem from "./VideoListItem";
type Props = {
  videos: TVideo[] | null;
};

export default function VideoList({ videos }: Props) {
  if (!videos || videos.length === 0) {
    return (
      <div className="w-full flex items-center justify-center">
        <p>Nenhum vídeo encontrado</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {videos?.map((video, idx) => (
        <VideoListItem key={video.id} video={video} videoIdx={idx + 1} />
      ))}
    </div>
  );
}
