import firebase from "./FirebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";

const storage = firebase.storage;

const uploadFile = (
  file: Blob | Uint8Array | ArrayBuffer,
  fullFilePath: string | undefined,
  progressCallback: (arg0: number) => void
) => {
  const uploadRef = ref(storage, fullFilePath);
  const uploadTask = uploadBytesResumable(uploadRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );

      progressCallback(progress);
    },
    (error) => {
      throw error;
    }
  );

  return uploadTask.then(async () => {
    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

    return downloadUrl;
  });
};

const deleteFile = async (fileDownloadUrl: string) => {
  const decodedUrl = decodeURIComponent(fileDownloadUrl);
  const startIndex = decodedUrl.indexOf("/o/") + 3;
  const endIndex = decodedUrl.indexOf("?");
  const filePath = decodedUrl.substring(startIndex, endIndex);

  const fileRef = ref(storage, filePath);
  try {
    return await deleteObject(fileRef);
  } catch (error) {
    const errorMsg = `FirebaseStorageService.deleteFile [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};
const getFileMetadata = async (fileDownloadUrl: string) => {
  const decodedUrl = decodeURIComponent(fileDownloadUrl);
  const startIndex = decodedUrl.indexOf("/o/") + 3;
  const endIndex = decodedUrl.indexOf("?");
  const filePath = decodedUrl.substring(startIndex, endIndex);

  const fileRef = ref(storage, filePath);
  try {
    const metadata = await getMetadata(fileRef);
    return metadata;
  } catch (error) {
    const errorMsg = `FirebaseStorageService.getFileMetadata [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};

const FirebaseStorageService = {
  uploadFile,
  deleteFile,
  getFileMetadata,
};

export default FirebaseStorageService;
