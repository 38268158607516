import { TEvent, EEventKind, EVENT_FEATURES } from "../../services/types";
import Button from "../../components/ui/Button";
import React, { useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import EventBanner from "./EventBanner";
import {
  InputCheckbox,
  InputCheckboxGroup,
  InputRadio,
  InputSelect,
  InputText,
} from "components/ui/form";
import dayjs from "dayjs";
import { DAYS_TO_RELEASE_FILES_OF_AN_EVENT } from "../../services/events";

type Props = {
  event?: TEvent | null;
  isEditable?: boolean;
  action?: string;
  method?: "get" | "post" | "delete" | "put" | "patch";
  onSubmit?: (values: TEventFormValues) => void;
};

export type TEventFormValues = {
  code: string;
  kind: EEventKind;
  features: string[];
  title: string;
  date: string;
  releaseDate: string;
  realtimeGallery: boolean;
  bannerUrl: string;
  owners: {
    email: string;
  }[];
  contacts: {
    name: string;
    email: string;
    whatsapp: string;
    reference: string;
    instagram: string;
    notify: string;
    owner: boolean;
  }[];
  city: string;
  state: string;
  country: string;
  totalGuests: string;
  customization: {
    theme: {
      name: string;
    };
    template: {
      name: string;
      titleFontSize: string;
      titleFontFamily: string;
      titleOneText: string;
      titleTwoText: string;
      subtitleText: string;
    };
  };
  languages: string[];
};

const EventForm = React.forwardRef(
  (
    { event, isEditable = false, onSubmit }: Props,
    ref?: React.Ref<HTMLFormElement>
  ) => {
    const [releaseDateDisabled, setReleaseDateDisabled] = React.useState(false);

    const defaultFeatures = useMemo(() => {
      if (event?.features) {
        return event?.features;
      }

      return Object.keys(EVENT_FEATURES);
    }, [event]);

    const defaultOwners = useMemo(() => {
      if (event?.owners) {
        return event?.owners.map((email) => ({ email }));
      }
      return [{ email: "" }];
    }, [event]);

    const defaultContacts = useMemo(() => {
      if (event?.contacts && event?.contacts?.length > 0) {
        return event?.contacts;
      }

      return [
        {
          name: "",
          email: "",
          whatsapp: "",
          reference: "",
          instagram: "",
          notify: "false",
          owner: false,
        },
      ];
    }, [event]);

    const defaultCustomization = useMemo(() => {
      if (event?.customization) {
        return event?.customization;
      }
      return {
        theme: {
          name: "rose",
        },
        template: {
          name: "wedding-rose",
          titleFontSize: "",
          titleFontFamily: "default",
          titleOneText: "",
          titleTwoText: "",
          subtitleText: "Grave sua mensagem",
        },
      };
    }, [event]);

    const defaultCountry = useMemo(() => {
      if (event?.country) {
        return event?.country;
      }
      return "Brasil";
    }, [event]);

    const defaultLanguages = useMemo(() => {
      if (event?.languages) {
        return event?.languages;
      }
      return ["pt-BR", "en-US"];
    }, [event]);

    const {
      register,
      formState: { errors },
      control,
      handleSubmit,
      getValues,
      setValue,
    } = useForm<TEventFormValues>({
      disabled: !isEditable,
      defaultValues: {
        code: event?.code,
        kind: event?.kind,
        features: defaultFeatures,
        title: event?.title,
        date: event?.date,
        realtimeGallery: event?.realtimeGallery || false,
        releaseDate: event?.releaseDate,
        contacts: defaultContacts,
        city: event?.city,
        state: event?.state,
        country: defaultCountry,
        totalGuests: event?.totalGuests,
        customization: defaultCustomization,
        languages: defaultLanguages,
        owners: defaultOwners,
      },
    });

    const {
      fields: ownersFields,
      append: ownersAppend,
      remove: ownersRemove,
    } = useFieldArray({
      control,
      name: "owners",
    });

    const {
      fields: contactFields,
      append: contactAppend,
      remove: contactRemove,
      insert: contactInsert,
    } = useFieldArray({ control, name: "contacts" });

    const addOwner = () => {
      const index = ownersFields.length;

      contactInsert(index, {
        name: "",
        email: "",
        whatsapp: "",
        reference: "",
        instagram: "",
        notify: "true",
        owner: true,
      });

      ownersAppend({ email: "" });
    };

    const removeOwner = (index: number) => {
      ownersRemove(index);

      contactRemove(index);
    };

    const addContact = () => {
      contactAppend({
        name: "",
        email: "",
        whatsapp: "",
        reference: "",
        instagram: "",
        notify: "false",
        owner: false,
      });
    };

    const validateEmail = (email: string) => {
      if (!email) return;

      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        return "Email inválido";
      }
    };

    const languagesList = useMemo(() => {
      return [
        { label: "Português", value: "pt-BR" },
        { label: "Inglês", value: "en-US" },
        { label: "Espanhol", value: "es-ES" },
        { label: "Francês", value: "fr-FR" },
        { label: "Vietnamita", value: "vi-VN" },
      ];
    }, []);

    const featuresOptions = useMemo(() => {
      return Object.keys(EVENT_FEATURES).map((key) => ({
        label: EVENT_FEATURES[key as keyof typeof EVENT_FEATURES],
        value: key,
      }));
    }, []);

    const handleSave = handleSubmit((data) => {
      if (onSubmit) {
        onSubmit(data);
      } else {
        console.warn("submit called without a callback wasn't set");
      }
    });

    const onRealtimeGalleryChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const realTimeGallery = event.target.checked;
      if (realTimeGallery) {
        const eventDate = getValues("date");
        const releaseDate = dayjs(eventDate)
          .add(DAYS_TO_RELEASE_FILES_OF_AN_EVENT, "day")
          .format("YYYY-MM-DD");
        setValue("releaseDate", releaseDate);
      }

      setReleaseDateDisabled(realTimeGallery);
    };

    const handleOwnerChange = (clickEvent: any) => {
      const index = clickEvent.target.name.split(".")[1];
      const value = clickEvent.target.value;

      setValue(`contacts.${index}.email`, value);

      console.log("handleOwnerChange", index, value);
    };

    useEffect(() => {
      if (event) {
        const realtimeGallery = event.realtimeGallery as boolean;
        setReleaseDateDisabled(realtimeGallery);
      }
    }, [event]);

    return (
      <form
        autoComplete="off"
        className="w-full"
        onSubmit={handleSave}
        noValidate={true}
        ref={ref}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
      >
        <h2 className="mb-5">Dados do evento</h2>

        <div className="w-full flex items-start gap-5">
          <div className="w-1/2 flex flex-col items-start gap-3">
            <input type="hidden" {...register("code")} id="code" />
            <InputText
              label="Título Interno*"
              register={register("title", { required: "Obrigatório" })}
              field="title"
              type="text"
              error={errors.title}
              isEditable={isEditable}
            />

            <InputText
              label="Data do Evento* (último dia do evento)"
              register={register("date", {
                required: "Obrigatório",
              })}
              field="date"
              type="date"
              error={errors.date}
              isEditable={isEditable}
            />

            <InputCheckbox
              label="Galeria em tempo real?"
              register={register("realtimeGallery")}
              field="realtimeGallery"
              error={errors.realtimeGallery}
              disabled={!isEditable}
              onChange={onRealtimeGalleryChange}
            />

            <InputText
              label="Data de Entrega"
              register={register("releaseDate", { required: "Obrigatório" })}
              field="releaseDate"
              type="date"
              error={errors.releaseDate}
              isEditable={isEditable}
              disabled={releaseDateDisabled}
            />

            <InputCheckboxGroup
              label="Funcionalidades"
              register={register("features", { required: "Obrigatório" })}
              field="features"
              options={featuresOptions}
              disabled={!isEditable}
              error={errors.features?.message}
            />
          </div>
          <div className="w-1/2 flex flex-col items-start gap-3">
            <InputText
              label="Cidade"
              register={register("city")}
              field="city"
              type="text"
              error={errors.city}
              isEditable={isEditable}
            />

            <InputText
              label="Estado"
              register={register("state")}
              field="state"
              type="text"
              error={errors.state}
              isEditable={isEditable}
            />
            <InputText
              label="País"
              register={register("country")}
              field="country"
              type="text"
              error={errors.country}
              isEditable={isEditable}
            />
            <InputText
              label="Número de convidados"
              register={register("totalGuests")}
              field="totalGuests"
              type="text"
              error={errors.totalGuests}
              isEditable={isEditable}
            />
          </div>
        </div>

        <h2 className="mt-10 mb-5">Quem pode alterar este evento?</h2>

        <div className="w-full space-y-5">
          {isEditable ? (
            <>
              {ownersFields?.map((owner, index) => (
                <div className="flex gap-5" key={owner.id}>
                  <div className="w-1/2 flex">
                    <InputText
                      label="Email"
                      register={register(`owners.${index}.email`, {
                        validate: validateEmail,
                      })}
                      field={`owners.${index}`}
                      type="email"
                      error={errors.owners?.[index]?.email}
                      isEditable={isEditable}
                      onInput={handleOwnerChange}
                    />
                  </div>

                  {isEditable && (
                    <div className="flex items-end">
                      <Button
                        className="bg-rose-200"
                        type="button"
                        onClick={() => removeOwner(index)}
                      >
                        Remover
                      </Button>
                    </div>
                  )}
                </div>
              ))}

              <Button className="max-w-[200px] bg-rose-400" onClick={addOwner}>
                Adicionar proprietário
              </Button>
            </>
          ) : (
            <div className="w-full flex">
              {event?.owners?.join(", ") || "Nenhum cliente pode editar."}.
            </div>
          )}
        </div>

        <h2 className="mt-10 mb-5">Dados de contato</h2>
        <div className="w-full space-y-10">
          {contactFields.map((contact, index) => (
            <div key={contact.id} className="w-full flex items-start gap-5">
              <div className="w-2/5 flex flex-col items-start gap-3">
                <InputText
                  label="Nome"
                  register={register(`contacts.${index}.name`)}
                  field={`contacts[${index}]name`}
                  type="text"
                  error={errors.contacts?.[index]?.name}
                  isEditable={isEditable}
                />

                <InputText
                  label="Email"
                  register={register(`contacts.${index}.email`)}
                  field={`contacts.${index}.email`}
                  type="email"
                  error={errors.contacts?.[index]?.email}
                  isEditable={isEditable}
                  disabled={contact.owner}
                />

                <InputText
                  label="Whatsapp"
                  register={register(`contacts.${index}.whatsapp`)}
                  field={`contacts.${index}.whatsapp`}
                  type="text"
                  error={errors.contacts?.[index]?.whatsapp}
                  isEditable={isEditable}
                />
              </div>
              <div className="w-2/5 flex flex-col items-start gap-3">
                <InputText
                  label="Referência"
                  register={register(`contacts.${index}.reference`)}
                  field={`contacts.${index}.reference`}
                  type="text"
                  error={errors.contacts?.[index]?.reference}
                  isEditable={isEditable}
                />
                <InputText
                  label="Instagram"
                  register={register(`contacts.${index}.instagram`)}
                  field={`contacts.${index}.instagram`}
                  type="text"
                  error={errors.contacts?.[index]?.instagram}
                  isEditable={isEditable}
                />
                <InputRadio
                  label="Notificar por Email"
                  register={register(`contacts.${index}.notify`)}
                  field={`contacts.${index}.notify`}
                  type="radio"
                  options={[
                    { label: "Sim", value: "true" },
                    { label: "Não", value: "false" },
                  ]}
                  value={contact.notify}
                  error={errors.contacts?.[index]?.notify}
                />
              </div>
              <div className="w-1/5 h-full flex flex-col items-center justify-center gap-3 pt-5">
                {isEditable && (
                  <Button
                    type="button"
                    disabled={contact.owner}
                    onClick={() => contactRemove(index)}
                    className="max-w-[150px] bg-rose-200"
                  >
                    Remover
                  </Button>
                )}
              </div>
            </div>
          ))}
          {isEditable && (
            <Button
              className="max-w-[250px] text-white bg-red-400"
              onClick={addContact}
            >
              Adicionar contato
            </Button>
          )}
        </div>

        <h2 className="mt-10 mb-5">Design do evento</h2>
        <div className="w-full flex items-start gap-5 pb-20">
          <div className="w-1/2 flex flex-col items-start gap-3">
            <h3>Sistema</h3>
            <div className="w-full bg-white border border-gray-400 p-4 mb-5">
              <InputRadio
                label="Theme"
                register={register("customization.theme.name")}
                field="customization.theme.name"
                options={[
                  { label: "Rose", value: "rose" },
                  { label: "White", value: "white" },
                ]}
                type="radio"
                value={event?.customization?.theme?.name}
                error={errors.customization?.theme?.name}
              />
              <div className="flex items-center justify-start gap-5">
                {languagesList.map((language) => {
                  return (
                    <InputCheckbox
                      key={language.value}
                      register={register(`languages`)}
                      label={language.label}
                      field={`languages`}
                      value={language.value}
                      error={errors.languages}
                    />
                  );
                })}
              </div>
            </div>
            <h3>Tela inicial</h3>
            <div className="w-full bg-white border border-gray-400 p-4 space-y-5">
              <InputSelect
                label="Template"
                register={register("customization.template.name")}
                field="customization.template.name"
                options={[
                  { label: "Rose - casamento", value: "wedding-rose" },
                  { label: "White - casamento", value: "wedding-white" },
                  { label: "Pink - aniversário", value: "birthday-pink" },
                  { label: "Yellow - aniversário", value: "birthday-yellow" },
                  { label: "Blue - aniversário", value: "birthday-blue" },
                  { label: "Purple - aniversário", value: "birthday-purple" },
                  { label: "Green - aniversário", value: "birthday-green" },
                ]}
                error={errors.customization?.template?.name}
                isEditable={isEditable}
              />
              <InputText
                label="Tamanho do título"
                register={register("customization.template.titleFontSize")}
                field="customization.template.titleFontSize"
                type="text"
                error={errors.customization?.template?.titleFontSize}
                isEditable={isEditable}
              />
              <InputRadio
                label="Fonte"
                register={register("customization.template.titleFontFamily")}
                field="customization.template.titleFontFamily"
                options={[
                  { label: "Default", value: "default" },
                  { label: "High spirited", value: "font-high-spirited" },
                  { label: "Style Script", value: "font-style-script" },
                  { label: "Playfair Display", value: "font-playfair-display" },
                ]}
                type="radio"
                value={event?.customization?.template?.titleFontFamily}
                error={errors.customization?.template?.titleFontFamily}
              />
              <InputText
                label="Título 1"
                register={register("customization.template.titleOneText")}
                field="customization.template.titleOneText"
                type="text"
                error={errors.customization?.template?.titleOneText}
                isEditable={isEditable}
              />
              <InputText
                label="Título 2 - [somente para template white-casamento]"
                register={register("customization.template.titleTwoText")}
                field="customization.template.titleTwoText"
                type="text"
                error={errors.customization?.template?.titleTwoText}
                isEditable={isEditable}
              />
              <InputText
                label="Subtítulo"
                register={register("customization.template.subtitleText")}
                field="customization.template.subtitleText"
                type="text"
                error={errors.customization?.template?.subtitleText}
                isEditable={isEditable}
              />
            </div>
          </div>
          <div className="w-1/2 flex flex-col items-start gap-3">
            {event && (
              <>
                <h3>Banner</h3>
                <EventBanner event={event} isEditable={isEditable} />
              </>
            )}
          </div>
        </div>

        {isEditable && (
          <div className="pb-20 w-full flex items-center justify-center">
            <Button type="submit" className="max-w-[150px] bg-green-200">
              Salvar
            </Button>
          </div>
        )}
      </form>
    );
  }
);

export default EventForm;
