import { useEffect } from "react";

type NotificationProps = {
  children: React.ReactNode;
  open: boolean;
  timeout?: number;
  setOpen: (value: boolean) => void;
};

const Notification = (props: NotificationProps) => {
  const { children, open, setOpen, timeout = 3000 } = props;

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, timeout);
    }
  }, [open, setOpen, timeout]);

  if (!open) {
    return null;
  }

  return (
    <div className="bg-green-400 p-4 text-white text-lg rounded-lg fixed right-10 bottom-5 z-10 transition-opacity duration-500 ease-in-out opacity-100">
      {children}
    </div>
  );
};

export default Notification;
