type InputProps = {
  label: string;
  register: any;
  error?: any;
  field: string;
  type?: string;
  defaultValue?: string;
  options?: { label: string; value: string }[];
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputCheckbox({
  label,
  register,
  error,
  field,
  type,
  value,
  defaultChecked,
  ...rest
}: InputProps) {
  const id = `${field}-${value}`;

  return (
    <label
      key={label}
      htmlFor={id}
      className="flex items-center justify-start gap-2"
    >
      <input
        type="checkbox"
        {...register}
        name={field}
        id={id}
        value={value}
        className="focus:outline-none focus:ring-0 w-5"
        {...rest}
      />
      {label}
    </label>
  );
}

export default InputCheckbox;
