import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import FirebaseFirestore from "@firebase/firestore-types";
import { TDocument, TLog } from "./types";

import { ActionFunctionArgs } from "react-router-dom";

import { TQueryDocument } from "@libs/firebase/Firebase.types";
const COLLECTION = "logs";

export const getLogsByEventId = async ({
  params,
}: ActionFunctionArgs | any) => {
  const { id } = params;
  let queries = [];
  let perPage = 500;

  if (!id) return null;

  queries.push({
    field: "eventId",
    condition: "==" as FirebaseFirestore.WhereFilterOp,
    value: id,
  });

  const args: TQueryDocument = {
    collection: COLLECTION,
    orderByField: "createdAt",
    orderByDirection: "desc",
    queries: queries,
    perPage: perPage,
  };

  const response = await FirebaseFirestoreService.readDocuments(args);

  const logs = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return logs;
};

export const createLog = async (data: TLog) => {
  const response = await FirebaseFirestoreService.createDocument(COLLECTION, {
    ...data,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
  return { id: response.id, ...data };
};
