import { useLocation, useNavigate } from "react-router-dom";
import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import React from "react";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get("error");

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
      navigate("/admin", { replace: true });
    } catch (error) {
      console.log("Login error: ", error);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-start gap-2">
        <button
          type="button"
          onClick={handleLoginWithGoogle}
          className="button flex items-center justify-center bg-red-400 hover:bg-red-500 border border-red-400 text-white"
        >
          Login with Google
        </button>
        {error && <p className="text-red-400">Error: {error}</p>}
      </div>
    </div>
  );
}
