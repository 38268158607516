import NavigationLink from "components/ui/NavigationLink";
import { Outlet } from "react-router-dom";

export default function EventCreationLayout() {
  const context = {};

  return (
    <div className="w-full">
      <h1>Criar evento</h1>

      <div className="flex mt-10 border-b border-red-500">
        <NavigationLink to={`../events/create`}>Completo</NavigationLink>
        <NavigationLink to={`../events/create/for-customer-edition`}>
          Simplificado
        </NavigationLink>
      </div>
      <div className="bg-white px-10 py-5">
        <Outlet context={context} />
      </div>
    </div>
  );
}
