import NavigationLink from "components/ui/NavigationLink";
import { EEventKind, TEvent } from "services/types";

type Props = {
  event: TEvent;
};

export default function EventNav({ event }: Props) {
  const recorderEvent = event.kind === EEventKind.Recorder;

  return (
    <div className="w-full flex flex-col gap-10">
      <div className="flex items-center justify-between">
        <h1>{event.title}</h1>
        <div className="bg-red-300 rounded-3xl py-2 px-5 text-white font-bold">{`Código: ${event.code}`}</div>
      </div>
      <div className="flex items-center justify-start gap-2 border-b border-red-400">
        <NavigationLink to={`../events/${event.id}`}>Evento</NavigationLink>
        <NavigationLink to={`../events/${event.id}/actions`}>
          Ações
        </NavigationLink>
        <NavigationLink to={`../events/${event.id}/uploads`}>
          Uploads
        </NavigationLink>

        {recorderEvent && (
          <NavigationLink to={`../events/${event.id}/videos`}>
            Vídeos (Legado)
          </NavigationLink>
        )}

        <NavigationLink to={`../events/${event.id}/logs`}>Logs</NavigationLink>
      </div>
    </div>
  );
}
