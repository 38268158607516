import { TEvent, TUpload } from "@services/types";
import dayjs from "dayjs";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { eventBanner, eventTitleFormatter } from "utils/event";
import eventTablePhoto from "../../assets/images/bg-customer-login.png";
import { isEventFilesAvailable } from "../../services/events";
import { useMemo, useState } from "react";
import Modal from "components/ui/Modal";
import ServiceTermsForm from "components/customer/ServiceTermsForm";
import Cookies from "js-cookie";

type TEventExtended = TEvent & { uploads: TUpload[] };

const Home = () => {
  const event = useLoaderData() as TEventExtended;
  const { eventCode } = useParams();
  const [isAcceptTermsModalOpen, setIsAcceptTermsModalOpen] = useState(
    Cookies.get("acceptTerms") == null
  );
  const eventTitle = eventTitleFormatter(event);
  const eventDateFormatted = dayjs(event.date).format("DD | MM | YYYY");
  const eventBannerUrl = eventBanner(event);
  const navigate = useNavigate();

  const isGalleryAvailable = useMemo(
    () => isEventFilesAvailable(event),
    [event]
  );

  const mdGridClass = useMemo(() => {
    if (isGalleryAvailable) return "md:grid-cols-3";

    return "md:grid-cols-2";
  }, [isGalleryAvailable]);

  const handleEventEditClick = () =>
    navigate(`/customer-panel/${eventCode}/edit`);

  const handleQRCodeClick = () =>
    navigate(`/customer-panel/${eventCode}/qrcode`);

  const handleGalleryClick = () =>
    navigate(`/customer-panel/${eventCode}/gallery`);

  const handleAcceptTermsModalClose = () => {
    setIsAcceptTermsModalOpen(false);
  };

  return (
    <>
      <div className="max-w-screen-xl w-full mx-auto px-10 pb-16">
        <div className="flex justify-between items-center gap-4 mb-10 md:mb-14 relative">
          <div className="grow hidden md:flex" />
          <div className="w-full relative md:absolute left-1/2 transform -translate-x-1/2">
            <h1 className="text-3xl text-center font-playfair-display font-normal">
              {eventTitle}
            </h1>
          </div>
          <div className="hidden md:flex justify-end">
            <div className="bg-primary-300 text-white py-2.5 px-5 rounded-full inline-block">
              <p className="font-century-gothic font-semibold text-sm">
                Código: {eventCode}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`grid grid-cols-1 ${mdGridClass} md:justify-items-center gap-10 md:gap-6`}
        >
          {/* Event Card */}
          <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] w-full md:w-[330px] flex flex-col">
            <div className="bg-primary-300 rounded-t-lg py-2">
              <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
                Seu Evento
              </h2>
            </div>
            <div className="grow flex flex-col items-center justify-center p-3">
              <div
                className={`w-full h-full rounded-lg bg-cover bg-center relative`}
                style={{ backgroundImage: `url(${eventBannerUrl})` }}
              >
                <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                  <div className="flex flex-col items-start text-white">
                    <p className="text-xl font-semibold font-playfair-display">
                      {eventTitle}
                    </p>
                    <p className="text-xs font-century-gothic">
                      {eventDateFormatted}
                    </p>
                  </div>
                  <button
                    className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                    onClick={handleEventEditClick}
                  >
                    Editar evento
                  </button>
                </div>
                <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
              </div>
            </div>
          </div>

          {/* QR Code Card */}
          <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] w-full md:w-[330px] flex flex-col">
            <div className="bg-primary-300 rounded-t-lg py-2">
              <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
                QR Code e Cartão
              </h2>
            </div>
            <div className="grow flex flex-col items-center justify-center p-3">
              <div className="w-full h-full rounded-lg bg-[url('../../assets/images/event-card-model1.png')] bg-cover bg-center relative">
                <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                  <button
                    className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                    onClick={handleQRCodeClick}
                  >
                    Ver mais
                  </button>
                </div>
                <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
              </div>
            </div>
          </div>

          {/* Gallery Card */}
          {isGalleryAvailable && (
            <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] grow w-full md:w-[330px] flex flex-col">
              <div className="bg-primary-300 rounded-t-lg py-2">
                <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
                  Seu Álbum
                </h2>
              </div>
              <div className="grow flex flex-col items-center justify-center p-3">
                <div
                  className="w-full h-full rounded-lg bg-cover bg-center relative"
                  style={{
                    backgroundImage: `url(${eventTablePhoto})`,
                  }}
                >
                  <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                    <button
                      className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                      onClick={handleGalleryClick}
                    >
                      Ver álbum
                    </button>
                  </div>
                  <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isAcceptTermsModalOpen}>
        <ServiceTermsForm onAcceptTerms={handleAcceptTermsModalClose} />
      </Modal>
    </>
  );
};

export default Home;
