import { getCardLink } from "../../services/events";
import { TEvent } from "@services/types";
import { useCallback, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import qrCodeExample1 from "../../assets/images/event-card-model1.png";
import qrCodeExample2 from "../../assets/images/event-card-model2.png";
import qrCodeExample3 from "../../assets/images/event-card-model3.png";
import { toPng } from "html-to-image";
import { eventTitleFormatter } from "utils/event";
import QRCode from "../../components/ui/QRCode";

const QRCodePage = () => {
  const event = useLoaderData() as TEvent;
  const eventTitle = eventTitleFormatter(event);
  const publicEventLink = `https://evento.capsuladotempo.com/?code=${event.code}`;
  const qrCodeSize = 500;
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const largeQrCodeSize = 1000;
  const largeQrCodeRef = useRef<HTMLDivElement>(null);

  const downloadQRCode = useCallback(async () => {
    if (qrCodeRef.current === null) return;
    try {
      const dataUrl = await toPng(qrCodeRef.current, {
        cacheBust: true,
        canvasHeight: qrCodeSize,
        canvasWidth: qrCodeSize,
        width: qrCodeSize,
        height: qrCodeSize,
        skipFonts: true,
      });
      const link = document.createElement("a");
      link.download = `QR Code ${eventTitle}.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, [eventTitle]);

  const downloadLargeQRCode = useCallback(async () => {
    if (largeQrCodeRef.current === null) return;
    try {
      const dataUrl = await toPng(largeQrCodeRef.current, {
        cacheBust: true,
        canvasHeight: largeQrCodeSize,
        canvasWidth: largeQrCodeSize,
        width: largeQrCodeSize,
        height: largeQrCodeSize,
        skipFonts: true,
      });
      const link = document.createElement("a");
      link.download = `QR Code ${eventTitle}.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, [eventTitle]);

  const downloadEventCardPDF = useCallback(
    async (cardModel: string) => {
      const url = getCardLink(cardModel, event, publicEventLink, "pdf");
      window.location.href = url;
    },
    [event, publicEventLink]
  );

  const downloadEventCardPNG = useCallback(
    async (cardModel: string) => {
      const url = getCardLink(cardModel, event, publicEventLink, "png");

      const response = await fetch(url);
      const blob = await response.blob();
      const urlBlob = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.download = `${eventTitle}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlBlob);
    },
    [event, eventTitle, publicEventLink]
  );

  return (
    <div className="max-w-screen-xl w-full mx-auto pb-16">
      <div className="flex flex-col justify-center items-center gap-10 mb-10 px-10 relative">
        <h1 className="text-3xl text-center font-playfair-display font-normal">
          QR Code e Cartão
        </h1>
        <p className="text-sm font-century-gothic font-medium max-w-[510px] text-center">
          Veja abaixo como imprimir seus QR Codes para o dia da festa. <br />
          <span className="font-century-gothic-bold">
            Recomendamos colocar em todas as mesas
          </span>
          , com as lembranças, no bar, em um quadro grande... e em qualquer
          outro lugar que você quiser! Quanto mais melhor!
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-6 mb-14 md:mb-24 px-10">
        {/* QR Code Card */}
        <div className="bg-white shadow-md rounded-lg min-h-[380px] md:min-h-[410px] w-full md:w-[310px] flex flex-col mx-auto">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Seu QR Code
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-center p-6 md:px-3 md:pb-3 gap-5">
            <h1 className="font-century-gothic text-center font-normal text-black text-sm">
              Esse é o seu QR Code exclusivo!
            </h1>
            <h2 className="font-century-gothic text-center font-normal text-black text-sm">
              Ele não tem fundo, perfeito para você aplicar em uma arte
              personalizada da sua escolha
            </h2>
            <div>
              <div className="hidden">
                <div ref={qrCodeRef}>
                  <QRCode link={publicEventLink} size={qrCodeSize} />
                </div>
                <div ref={largeQrCodeRef}>
                  <QRCode link={publicEventLink} size={largeQrCodeSize} />
                </div>
              </div>

              <QRCode link={publicEventLink} />
            </div>
            <div className="w-full flex flex-col relative gap-1">
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={downloadQRCode}
              >
                Baixar QR Code (png)
              </button>
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={downloadLargeQRCode}
              >
                Baixar QR Code (png) HD <br />
                (recomendado para banner)
              </button>
            </div>
          </div>
        </div>

        {/* Grave sua mensagem  */}
        <div className="bg-white shadow-md rounded-lg min-h-[380px] w-full md:w-[310px] flex flex-col mx-auto">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Cartão para impressão
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-start p-6 md:px-3 md:pb-3">
            <div className="max-h-[85px] grow mb-2.5 flex flex-col gap-5">
              <h1 className="font-century-gothic text-center font-normal text-black text-sm">
                MODELO - GRAVE SUA MENSAGEM
              </h1>
              <h2 className="font-century-gothic text-center font-normal text-black text-sm mb-8">
                Esse é o seu cartão para colocar nas mesas. Está pronto: é só
                imprimir!
              </h2>
            </div>
            <div className="grow w-full rounded-lg overflow-hidden mb-2.5">
              <div
                className="w-full h-full mx-auto bg-cover bg-center"
                style={{
                  backgroundImage: `url(${qrCodeExample1})`,
                }}
              />
            </div>
            <div className="w-full flex items-center relative gap-1">
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPNG("model1")}
              >
                Baixar .png
              </button>
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPDF("model1")}
              >
                Baixar .pdf
              </button>
            </div>
          </div>
        </div>

        {/* Compartilhe momentos  */}
        <div className="bg-white shadow-md rounded-lg min-h-[380px] w-full md:w-[310px] flex flex-col mx-auto">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Cartão para impressão
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-start p-6 md:px-3 md:pb-3">
            <div className="max-h-[85px] grow mb-2.5 flex flex-col gap-5">
              <h1 className="font-century-gothic text-center font-normal text-black text-sm">
                MODELO - COMPARTILHE MOMENTOS
              </h1>
              <h2 className="font-century-gothic text-center font-normal text-black text-sm mb-8">
                Esse é o seu cartão para colocar nas mesas. Está pronto: é só
                imprimir!
              </h2>
            </div>
            <div className="grow w-full rounded-lg overflow-hidden mb-2.5">
              <div
                className="w-full h-full mx-auto bg-cover bg-center"
                style={{
                  backgroundImage: `url(${qrCodeExample2})`,
                }}
              />
            </div>
            <div className="w-full flex items-center relative gap-1">
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPNG("model2")}
              >
                Baixar .png
              </button>
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPDF("model2")}
              >
                Baixar .pdf
              </button>
            </div>
          </div>
        </div>

        {/* Cápsula do Tempo  */}
        <div className="bg-white shadow-md rounded-lg min-h-[380px] w-full md:w-[310px] flex flex-col mx-auto">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Cartão para impressão
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-start p-6 md:px-3 md:pb-3">
            <div className="max-h-[85px] grow mb-2.5 flex flex-col gap-5">
              <h1 className="font-century-gothic text-center font-normal text-black text-sm flex flex-col gap-5">
                MODELO - CÁPSULA DO TEMPO
              </h1>
              <h2 className="font-century-gothic text-center font-normal text-black text-sm mb-8">
                Esse é o seu cartão para colocar nas mesas. Está pronto: é só
                imprimir!
              </h2>
            </div>
            <div className="grow w-full rounded-lg overflow-hidden mb-2.5">
              <div
                className="w-full h-full mx-auto bg-cover bg-center"
                style={{
                  backgroundImage: `url(${qrCodeExample3})`,
                }}
              />
            </div>
            <div className="w-full flex items-center relative gap-1">
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPNG("model3")}
              >
                Baixar .png
              </button>
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={() => downloadEventCardPDF("model3")}
              >
                Baixar .pdf
              </button>
            </div>
          </div>
        </div>

        {/* Banner Card */}
        {/* TODO Implement endpoints to generate banner arts */}
        {/* <div className="bg-white shadow-md rounded-lg min-h-[380px] md:min-h-[410px] w-full md:w-[310px] flex flex-col">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Banner para impressão
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-start p-6 md:px-3 md:pb-3">
            <div className="max-h-[60px] grow mb-2.5">
              <h1 className="font-century-gothic text-center font-normal text-black text-sm">
                Se preferir também pode colocar um banner no seu evento com o QR
                Code (em alta definição)!
              </h1>
            </div>
            <div className="grow w-full rounded-lg overflow-hidden mb-2.5">
              <div
                className="w-full h-full mx-auto bg-cover bg-center"
                style={{
                  backgroundImage: `url(${qrCodeExample2})`,
                }}
              />
            </div>
            <div className="w-full flex items-center relative gap-1">
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={downloadEventCardPDF}
              >
                Baixar .png
              </button>
              <button
                className="w-full bg-primary-500 text-white font-century-gothic font-semibold text-sm py-2 rounded-lg"
                onClick={downloadEventCardPDF}
              >
                Baixar .pdf
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex flex-col items-start justify-start gap-5 md:gap-11 md:px-10">
        <h1 className="font-playfair-display font-normal text-2xl px-10 text-center md:text-left md:px-0">
          Veja esse vídeo com dicas para imprimir o QR Code:
        </h1>
        <div className="w-full flex items-center justify-center">
          <div className="relative md:w-[775px] md:h-[400px] w-full h-[210px]">
            <iframe
              src="https://www.youtube.com/embed/s0olInl8K-4"
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
}; // 960 × 1708
export default QRCodePage;
