import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import EventNav from "./EventNav";
import { TEvent } from "@services/types";

export default function EventLayout() {
  const event = useLoaderData() as TEvent;

  const context = { event };

  return (
    <>
      <EventNav event={event} />
      <div className="w-full rounded-b-md">
        <Outlet context={context} />
      </div>
    </>
  );
}
