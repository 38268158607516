import { TUpload } from "@services/types";
import UploadListItem from "./UploadListItem";

type Props = {
  uploads: TUpload[] | null;
};

export default function UploadList({ uploads }: Props) {
  if (!uploads || uploads.length === 0) {
    return (
      <div className="w-full flex items-center justify-center">
        <p>Nenhum arquivo encontrado</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {uploads?.map((upload, idx) => (
        <UploadListItem key={upload.id} upload={upload} uploadIdx={idx + 1} />
      ))}
    </div>
  );
}
