export default function EventNotFound() {
  return (
    <div className="px-10 py-16 flex flex-col items-center">
      <h1 className="mb-9 text-theme-black text-center">
        Erro ao acessar o evento
      </h1>

      <div className={`flex flex-col items-center py-10 px-8 gap-2 `}>
        <p className="text-theme-black">Evento não encontrado ou expirado.</p>
      </div>
    </div>
  );
}
