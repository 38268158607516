import ServiceTermsText from "components/customer/ServiceTermsText";

const ServiceTerms = () => {
  return (
    <div className="bg-white mb-36">
      <ServiceTermsText />
    </div>
  );
};

export default ServiceTerms;
