import { NavLink } from "react-router-dom";

export default function NavigationLink({
  to,
  children,
  activeColorClass = "bg-red-400",
}: {
  to: string;
  children: React.ReactNode;
  activeColorClass?: string;
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        [
          isActive
            ? `font-bold text-white ${activeColorClass}`
            : "bg-gray-300 text-white",
          " rounded-t-xl py-2 px-10 no-underline text-lg",
        ].join(" ")
      }
      to={to}
      end
    >
      {children}
    </NavLink>
  );
}
