import Logo from "../../assets/svg/Logo";
import React, { useCallback, useMemo } from "react";
import { useLoaderData } from "react-router-dom";
import { TEvent } from "@services/types";
import { formatDate } from "utils/utils";
import { createLog } from "../../services/logs";

function VideosDownload() {
  const { event, videosCount } = useLoaderData() as {
    event: TEvent;
    videosCount: number;
  };

  const expireDate = useMemo(() => {
    const date = new Date(event?.releaseDate);
    date.setMonth(date.getMonth() + 6);
    return date.toLocaleDateString("pt-br", {
      timeZone: "UTC",
    });
  }, [event]);

  const isReleased = useMemo(() => {
    const currentDate = new Date();
    const releaseDate = new Date(event?.releaseDate);

    return currentDate >= releaseDate;
  }, [event]);

  const downloadLog = useCallback(async () => {
    const log = {
      eventId: event.id as string,
      message: `Clicou para fazer download.`,
    };
    await createLog(log);
  }, [event]);

  if (!event)
    return (
      <div className="grid h-screen max-h-full bg-rose-50 overflow-hidden auto-cols-auto grid-rows-[35px_auto]">
        <header className="w-full bg-red-400 text-white">
          <div className="h-full flex items-center justify-between max-w-6xl mx-auto px-4">
            <Logo width={150} />
          </div>
        </header>
        <div className="theme-body">
          <div className="pt-12 pb-5 w-full h-full flex flex-col justify-start items-start max-w-6xl mx-auto px-6">
            <h6 className="w-full text-2xl font-playfair-display font-normal text-center mb-12">
              Evento não encontrado.
            </h6>
          </div>
        </div>
      </div>
    );

  return (
    <div className="grid h-screen max-h-full bg-rose-50 overflow-hidden auto-cols-auto grid-rows-[35px_auto]">
      <header className="w-full bg-red-400 text-white">
        <div className="h-full flex items-center justify-between max-w-6xl mx-auto px-4">
          <Logo width={150} />
        </div>
      </header>
      <div className="theme-body">
        <div className="pt-12 pb-5 w-full h-full flex flex-col justify-start items-start max-w-6xl mx-auto px-6">
          <h6 className="w-full font-playfair-display font-normal text-3xl text-center mb-8 px-3">
            {event.title}
          </h6>
          <div className="flex flex-col items-center bg-white rounded-xl pt-12 px-3 md:px-20 pb-6 mx-auto max-w-2xl shadow-lg">
            {isReleased && event.compressed_video_collection_url ? (
              <>
                <div className="font-century-gothic space-y-5 mb-5 px-4">
                  <p className="text-center text-lg leading-8">
                    Suas Cápsulas do Tempo foram liberadas!
                  </p>
                  <p className="text-center text-lg leading-8">
                    Você recebeu{" "}
                    <span className="font-century-gothic-bold">
                      {videosCount} vídeos!
                    </span>
                  </p>
                  <p className="text-center text-lg leading-8">
                    Seus vídeos ficarão disponíveis para download até o dia:{" "}
                    <span className="font-century-gothic-bold">
                      {expireDate}
                    </span>
                  </p>
                </div>

                <div onClick={downloadLog}>
                  <a
                    href={event.compressed_video_collection_url}
                    className="button text-white text-lg font-century-gothic-bold bg-red-400 hover:bg-red-500 px-8 py-6"
                    download={`${event.title} - ${event.date}.zip`}
                  >
                    Baixar vídeos
                  </a>
                </div>
              </>
            ) : (
              <div className="font-century-gothic space-y-5 mb-3 px-4">
                <h6 className="text-center text-lg font-century-gothic-bold">
                  Quase lá...
                </h6>
                <p className="text-center font-century-gothic text-lg">
                  Suas Cápsulas do Tempo serão liberadas no dia:
                </p>
                <div className="bg-red-100 w-40 mx-auto">
                  <p className="font-century-gothic-bold text-center text-lg p-2">
                    <strong>{formatDate(event.releaseDate)}</strong>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(VideosDownload);
