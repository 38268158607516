import { TEvent, TUpload } from "../../services/types";
import { Gallery as GalleryGrid, Image } from "react-grid-gallery";
import { useLoaderData } from "react-router-dom";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Lightbox,
  IconButton,
  createIcon,
  SlideImage,
  SlideVideo,
  useLightboxState,
  addToolbarButton,
} from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Slideshow,
  Video,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { Play, Download, SpinnerIcon } from "../../assets/svg";
import Card from "components/ui/Card";
import dayjs, { Dayjs } from "dayjs";
import { eventTitleFormatter } from "utils/event";
import classNames from "classnames";
import { createLog } from "../../services/logs";
import { getUploadsByEventId } from "../../services/uploads";
import { isEventReleased } from "../../services/events";
import { formatDate } from "utils/utils";

type TEventExtended = TEvent & { uploads: TUpload[] };

const DAYS_UNTIL_CLOSE_DOWNLOADS = 35;
const VIDEO_FILE_EXTENSIONS = ["mp4", "webm", "mov"];

const isVideo = (fileExt: string) =>
  VIDEO_FILE_EXTENSIONS.includes(fileExt.toLowerCase());

const DownloadIcon = createIcon(
  "Download",
  <Download width={22} height={22} />
);

type UnreleasedTextCardProps = {
  filesCount: number;
  releaseDate: Dayjs;
};

const UnreleasedTextCard = (props: UnreleasedTextCardProps) => {
  const { filesCount, releaseDate } = props;

  return (
    <Card className="max-w-[600px] px-6 py-8 flex flex-col justify-center items-center gap-4 text-lg text-center">
      <span>
        Estamos <strong>aguardando seus convidados</strong> terminarem de enviar
        suas fotos.
      </span>
      <span>
        Até o momento você recebeu <strong>{filesCount} arquivos!</strong>
      </span>
      <span>
        Você poderá fazer o download do álbum no dia:{" "}
        <strong>{formatDate(releaseDate)}</strong>.
      </span>
      <span>
        Até lá você pode baixar as suas fotos preferidas individualmente,
        clicando na foto escolhida.
      </span>
    </Card>
  );
};

const buttonLabels = {
  initial: "Baixar arquivos",
  downloading: "Baixando <current>/<total> arquivos",
  done: "Download finalizado",
  error: "Continuar download",
};

type DownloadButtonProps = {
  onClick: () => void;
  status: "initial" | "downloading" | "done" | "error";
  progress?: Array<number>;
};

const DownloadButton = ({
  onClick,
  status,
  progress = [],
}: DownloadButtonProps) => {
  const disabled = status === "downloading" || status === "done";
  let label = buttonLabels[status];
  let bgColor;
  let hover;
  let isDisabled = false;
  let icon;

  switch (status) {
    case "downloading":
      bgColor = "bg-gray-400";
      isDisabled = true;

      icon = (
        <SpinnerIcon width={24} height={24} color="#ffffff" outline="#4b5563" />
      );

      // update the label with the current and total files
      const [current = 0, total = 0] = progress;

      if (total === 0) {
        label = "Iniciando download";
      } else {
        label = label
          .replace("<current>", current.toString())
          .replace("<total>", total.toString());
      }

      break;
    case "done":
      bgColor = "bg-green-400";
      isDisabled = true;
      break;
    default:
      bgColor = "bg-red-400";
      hover = "hover:bg-red-500";
      break;
  }

  const handleClick = async () => {
    onClick();
  };

  const btnClass = classNames(
    "button text-white text-lg font-century-gothic-bold px-4 py-6 flex justify-center",
    bgColor,
    hover,
    {
      "cursor-pointer": !isDisabled,
      "cursor-not-allowed": isDisabled,
    }
  );

  return (
    <button className={btnClass} onClick={handleClick} disabled={disabled}>
      {icon ? <span className="mr-2">{icon}</span> : null}
      {label}
    </button>
  );
};

type DownloadCardProps = { event: TEvent; filesCount: number };

const DownloadCard = (props: DownloadCardProps) => {
  const { event, filesCount } = props;
  const eventReleaseDate = dayjs(event.releaseDate);

  const [downloadStatus, setDownloadStatus] = useState<
    "initial" | "downloading" | "done" | "error"
  >("initial");
  const [downloadProgress, setDownloadProgress] = useState<[number, number]>([
    0, 0,
  ]);

  const expireDate = useMemo(() => {
    return eventReleaseDate.add(DAYS_UNTIL_CLOSE_DOWNLOADS, "day");
  }, [eventReleaseDate]);

  const lastDownloadedFileKey = useMemo(() => {
    return `lastDownloadedFile-${event.id}`;
  }, [event]);

  const downloadLog = useCallback(async () => {
    const log = {
      eventId: event.id as string,
      message: `Clicou para fazer download.`,
    };
    await createLog(log);
  }, [event]);

  const getLastDownloadedFile = useCallback(
    () => localStorage.getItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const removeLastDownloadedFile = useCallback(
    () => localStorage.removeItem(lastDownloadedFileKey),
    [lastDownloadedFileKey]
  );

  const saveLastDownloadedFile = useCallback(
    (filename: string) => localStorage.setItem(lastDownloadedFileKey, filename),
    [lastDownloadedFileKey]
  );

  // Check is the wasn't errors on the last visit
  useEffect(() => {
    if (getLastDownloadedFile() && downloadStatus === "initial") {
      console.debug("Download error found");

      setDownloadStatus("error");
    }
  }, [getLastDownloadedFile, downloadStatus]);

  const handleDownload = async () => {
    setDownloadStatus("downloading");

    const uploads = (await getUploadsByEventId(
      event.id as string
    )) as TUpload[];

    try {
      const lastDownloadedFile = getLastDownloadedFile();
      const lastDownloadedFileIndex = uploads.findIndex(
        (upload) => upload.fileName === lastDownloadedFile
      );
      const startIndex =
        lastDownloadedFileIndex > 0 ? lastDownloadedFileIndex + 1 : 0;

      await downloadAllFiles(uploads, startIndex);
    } catch (error) {
      setDownloadStatus("error");
    }
  };

  const downloadFile = async (upload: TUpload) => {
    const a = document.createElement("a");
    a.style.display = "none";

    const { fileStoragePath, convertedFileStoragePath } = upload;

    let publicTmpUrl, filename;

    if (convertedFileStoragePath) {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(convertedFileStoragePath)
      );
      filename = convertedFileStoragePath.split("/").pop() as string;
    } else {
      publicTmpUrl = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(fileStoragePath)
      );
      filename = upload.fileName;
    }

    const response = await fetch(publicTmpUrl);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    a.href = objectUrl;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(objectUrl);
  };

  const downloadAllFiles = async (
    uploads: TUpload[],
    startIndex: number = 0
  ) => {
    if (!uploads) return;

    for (let i = startIndex; i < uploads.length; i++) {
      const upload = uploads[i];

      console.debug(
        `Downloading file ${i + 1} of ${uploads.length}. Current file: ${
          upload.fileName
        }`
      );

      await downloadFile(uploads[i]);

      saveLastDownloadedFile(upload.fileName);

      setDownloadProgress([i + 1, uploads.length]);
    }

    // clear the download status
    setDownloadStatus("done");
    removeLastDownloadedFile();
  };

  const handleDownloadReset = async () => {
    removeLastDownloadedFile();
    await handleDownload();
  };

  return (
    <Card className="w-full h-full flex flex-col px-6 py-8 items-center justify-center cursor-pointer">
      <div className="font-century-gothic space-y-5 mb-5 px-4">
        <p className="text-center text-lg leading-8">Seu ábum está liberado!</p>
        <p className="text-center text-lg leading-8">
          Você recebeu{" "}
          <span className="font-century-gothic-bold">
            {filesCount} arquivos!
          </span>
        </p>
        <p className="text-center text-lg leading-8">
          Seus arquivos estão disponíveis para download até o dia:{" "}
          <span className="font-century-gothic-bold">
            {formatDate(expireDate)}
          </span>
          .
        </p>

        <p className="text-center text-lg leading-8">
          Salve seus arquivos em um dispositivo próprio (ex: drive, HD externo,
          etc).
        </p>
      </div>

      <div onClick={downloadLog}>
        <DownloadButton
          onClick={handleDownload}
          status={downloadStatus}
          progress={downloadProgress}
        />
      </div>

      {["done", "error"].includes(downloadStatus) && (
        <p className="text-sm mt-5">
          Caso você deseje reiniciar o processo de download,{" "}
          <span
            className="underline hover:no-underline focus:outline-none cursor-pointer"
            onClick={handleDownloadReset}
          >
            clique aqui
          </span>
          .
        </p>
      )}
    </Card>
  );
};

const DownloadToast = () => {
  return (
    <div className="w-[300px] bg-blue-500 absolute right-0 bottom-0 mr-5 mb-5 flex items-center z-10000">
      <div className="h-full flex items-center justify-center p-5">
        <SpinnerIcon width={36} height={36} color="white" outline="black" />
      </div>

      <span className="text-white font-century-gothic-bold">
        Preparando o download...
      </span>
    </div>
  );
};

const ThumbnailImageComponent = (props: any) => {
  const { item, imageProps } = props;

  const lastDotIndex = item.alt.lastIndexOf(".");
  const fileExt = item.alt.slice(lastDotIndex + 1);

  const { key, ...otherImageProps } = imageProps;

  if (isVideo(fileExt)) {
    return (
      <div key={key} className="w-full h-full relative">
        <img alt="" {...otherImageProps} />
        <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
          <Play width={32} height={32} color="#fff" />
        </div>
      </div>
    );
  }

  return <img key={key} alt="" {...otherImageProps} />;
};

export default function Gallery() {
  const event = useLoaderData() as TEventExtended;
  const eventTitle = eventTitleFormatter(event);
  const eventReleaseDate = dayjs(event.releaseDate);

  const [images, setImages] = useState<Image[]>([]);
  const [slides, setSlides] = useState<(SlideImage | SlideVideo)[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [showDownloadToast, setShowDownloadToast] = useState(false);
  const [galleryRowHeight, setGalleryRowHeight] = useState(200);

  const eventReleased = useMemo(() => isEventReleased(event), [event]);

  const handleGalleryClick = (index: number) => setFileIndex(index);
  const handleSlideshowClose = () => setFileIndex(-1);

  function DownloadButton() {
    const { currentIndex } = useLightboxState();

    const downloadFileOnClick = async (filename: string, url: string) => {
      setShowDownloadToast(true);

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);

        const linkElement = document.createElement("a");

        linkElement.style.display = "none";
        linkElement.href = fileUrl;
        linkElement.download = filename;

        document.body.appendChild(linkElement);

        linkElement.click();

        document.body.removeChild(linkElement);

        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error("Error downloading", JSON.stringify(error));
      } finally {
        setShowDownloadToast(false);
      }
    };

    const handleDownload = async () => {
      const upload = event.uploads[currentIndex];
      const { fileName, fileStoragePath } = upload;

      const url = await DigitalOceanSpaces.getFileUrl(
        encodeURIComponent(fileStoragePath)
      );

      await downloadFileOnClick(fileName, url);
    };

    return (
      <IconButton
        label="Download"
        icon={DownloadIcon}
        onClick={handleDownload}
        disabled={showDownloadToast}
      />
    );
  }

  function CustomDownloadPlugin({ augment }: { augment: any }) {
    augment(({ toolbar, ...restProps }: { toolbar: any }) => ({
      toolbar: addToolbarButton(toolbar, "download", <DownloadButton />),
      ...restProps,
    }));
  }

  const openSlideshow = useMemo(() => {
    return fileIndex >= 0;
  }, [fileIndex]);

  useEffect(() => {
    const setImagesAsync = async () => {
      const images = event.uploads.map(async (upload) => {
        const thumbnailKey = upload.thumbnailStoragePath;
        const image: Image = {
          src: await DigitalOceanSpaces.getFileUrl(
            encodeURIComponent(thumbnailKey as string)
          ),
          width: 200,
          height: 200,
          alt: upload.fileName,
        };

        return image;
      });

      setImages(await Promise.all(images));
    };

    const setSlidesAsync = async () => {
      const slides = event.uploads.map(async (upload) => {
        const { fileName, fileExt, fileStoragePath, optimizedFileStoragePath } =
          upload;
        const srcPath = optimizedFileStoragePath || fileStoragePath;
        const src = await DigitalOceanSpaces.getFileUrl(
          encodeURIComponent(srcPath)
        );

        if (isVideo(fileExt)) {
          return {
            type: "video",
            controls: true,
            autoPlay: true,
            sources: [
              {
                src,
                type: `video/mp4`,
              },
            ],
            title: fileName,
          } as SlideVideo;
        }

        return {
          type: "image",
          src,
          alt: fileName,
          title: fileName,
        } as SlideImage;
      });

      setSlides(await Promise.all(slides));
    };

    setImagesAsync();
    setSlidesAsync();
  }, [event]);

  useEffect(() => {
    // check if desktop or mobile
    const isDesktop = window.innerWidth > 768;

    if (isDesktop) {
      setGalleryRowHeight(300);
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-center mb-16">
      <h1 className="text-3xl text-center font-playfair-display font-normal">
        {eventTitle}
      </h1>

      <div className="flex items-center justify-center mt-5 px-8">
        {eventReleased ? (
          <DownloadCard event={event} filesCount={images.length} />
        ) : (
          <UnreleasedTextCard
            filesCount={images.length}
            releaseDate={eventReleaseDate}
          />
        )}
      </div>

      <div className="w-full h-full mt-8 px-1">
        <GalleryGrid
          thumbnailImageComponent={ThumbnailImageComponent}
          images={images}
          onClick={handleGalleryClick}
          enableImageSelection={false}
          rowHeight={galleryRowHeight}
        />

        <Lightbox
          slides={slides}
          open={openSlideshow}
          index={fileIndex}
          close={handleSlideshowClose}
          plugins={[
            Counter,
            CustomDownloadPlugin,
            Fullscreen,
            Slideshow,
            Video,
          ]}
        />
      </div>

      {showDownloadToast && <DownloadToast />}
    </div>
  );
}
