import FirebaseAuthService from "../libs/firebase/FirebaseAuthService";
import { User } from "firebase/auth";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { getEventByCode } from "./events";
import { TEvent } from "./types";

export const adminAuthLoader = () => {
  return new Promise((resolve, reject) => {
    const checkUser = (userFromFirebaseAuth: User | null) => {
      const allowedEmail = process.env.REACT_APP_ALLOWED_EMAIL;
      if (userFromFirebaseAuth && userFromFirebaseAuth.email === allowedEmail) {
        resolve(userFromFirebaseAuth);
      } else {
        if (
          userFromFirebaseAuth &&
          userFromFirebaseAuth.email !== allowedEmail
        ) {
          reject(redirect("/admin/login?error=user-not-allowed"));
        }
        reject(redirect("/admin/login"));
      }
    };
    FirebaseAuthService.subscribeToAuthChanges(checkUser);
  });
};

export const publicAuthLoader = ({ params }: LoaderFunctionArgs) => {
  const { eventCode } = params;
  const loginPath = `/public/${eventCode}/login`;

  return new Promise((resolve, reject) => {
    const checkUser = async (user: User | null) => {
      let event;

      if (!user) {
        return reject(
          redirect(
            `${loginPath}?error=É necessário estar logado para acessar o evento`
          )
        );
      }

      try {
        event = (await getEventByCode(eventCode as string)) as TEvent;
      } catch (error: any) {
        console.error("Authentication error", error);

        return reject(redirect("/public/event-not-found"));
      }

      if (
        event?.owners?.includes(user.email as string) ||
        user.email === process.env.REACT_APP_ALLOWED_EMAIL
      ) {
        return resolve({ user, event });
      }

      return reject(redirect("/public/event-not-found"));
    };

    FirebaseAuthService.subscribeToAuthChanges(checkUser);
  });
};
