import FirebaseFirestore from "@firebase/firestore-types";
import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import { TDocument } from "./types";
import { TQueryDocument } from "@libs/firebase/Firebase.types";
import { ActionFunctionArgs } from "react-router-dom";
import FirebaseStorageService from "../libs/firebase/FirebaseStorageService";
import * as logService from "./logs";

const COLLECTION = "videos";

export const getVideosByEventId = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  let queries = [];

  if (!id) return null;

  queries.push({
    field: "eventId",
    condition: "==" as FirebaseFirestore.WhereFilterOp,
    value: id,
  });

  const args: TQueryDocument = {
    collection: COLLECTION,
    orderByField: "updatedAt",
    orderByDirection: "asc",
    queries: queries,
  };

  const response = await FirebaseFirestoreService.readDocuments(args);

  const videos = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return videos;
};

export const getDataForVideoDownload = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const url = new URL(request.url);
  const email = url.searchParams.get("email");
  const { id } = params;
  let queries = [];

  if (!id) return null;

  const eventResponse = await FirebaseFirestoreService.readDocument(
    "events",
    id as string
  );
  const eventData = eventResponse.data();

  if (!eventData)
    return {
      event: null,
      videos: null,
    };

  const event = {
    id: eventResponse.id,
    ...eventData,
  };

  queries = [
    {
      field: "eventId",
      condition: "==" as FirebaseFirestore.WhereFilterOp,
      value: event.id,
    },
    {
      field: "status",
      condition: "==" as FirebaseFirestore.WhereFilterOp,
      value: "video_to_storage",
    },
  ];
  const videosCount = await FirebaseFirestoreService.countDocuments(
    "videos",
    queries
  );

  const log = {
    eventId: event.id as string,
    message: `Visitou a página de download. Email: ${
      email ? email : "Não identificado."
    }`,
  };

  await logService.createLog(log);

  return { event, videosCount };
};

export const getVideoMetadataByURL = async (videoUrl: string) => {
  const response = await FirebaseStorageService.getFileMetadata(videoUrl);
  return response;
};
