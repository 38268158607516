import { QRCodeSVG } from "qrcode.react";

type Props = {
  link: string;
  size?: number;
};

export default function QRCode({ link, size = 120 }: Props) {
  return (
    <div>
      <div className="mx-auto max-w-[150px] flex flex-col items-center justify-center">
        <QRCodeSVG
          className="scaling-svg"
          value={link}
          size={size}
          bgColor={"transparent"}
          fgColor={"#000000"}
          level={"L"}
          includeMargin={false}
        />
      </div>
    </div>
  );
}
