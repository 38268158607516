import { EVENT_FEATURES } from "services/types";
import { Badge } from "components/ui";

type TEventKindBadgeProps = {
  kind: string;
};

const EventKindBadge = ({ kind }: TEventKindBadgeProps) => {
  const label = EVENT_FEATURES[kind as keyof typeof EVENT_FEATURES];

  if (kind === "recorder") {
    return <Badge className="bg-green-600">{label}</Badge>;
  }

  if (kind === "uploader") {
    return <Badge className="bg-blue-600">{label}</Badge>;
  }

  return null;
};

export default EventKindBadge;
